// store/modules/chatData.js
import axios from "axios";

const state = {
  recipients: [],
  selectedRecipient: null,
  chats: [],
  loading: false,
  error: null,
};

const mutations = {
  setRecipients(state, recipients) {
    state.recipients = recipients;
  },
  setSelectedRecipient(state, recipient) {
    state.selectedRecipient = recipient;
  },
  setChats(state, chats) {
    state.chats = chats;
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
  setError(state, error) {
    state.error = error;
  },
};

const actions = {
  async fetchRecipients({ commit }, { jobId }) {
    commit("setLoading", true);
    try {
      const response = await axios.get(`${process.env.VUE_APP_API_URL}/chats/${jobId}/recipients`);
      commit("setRecipients", response.data);
      commit("setError", null);
    } catch (error) {
      commit("setError", error.message);
    } finally {
      commit("setLoading", false);
    }
  },
  async fetchChatHistory({ commit }, { jobId, recipient }) {
    commit("setLoading", true);
    try {
      const response = await axios.get(`${process.env.VUE_APP_API_URL}/chats/${jobId}?recipient=${encodeURIComponent(recipient)}`);
      commit("setChats", response.data);
      commit("setError", null);
    } catch (error) {
      commit("setError", error.message);
    } finally {
      commit("setLoading", false);
    }
  },
};

const getters = {
  getRecipients: state => state.recipients,
  getSelectedRecipient: state => state.selectedRecipient,
  getChats: state => state.chats,
  isLoading: state => state.loading,
  getError: state => state.error,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
