// store/modules/agentData.js
import axios from "axios";

const state = {
  agents: [],
  selectedAgent: {},
  loading: false,
  error: null,
};

const mutations = {
  setAgents(state, agents) {
    state.agents = agents;
  },
  setSelectedAgent(state, agent) {
    state.selectedAgent = agent;
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
  setError(state, error) {
    state.error = error;
  },
};

const actions = {
  async fetchAgents({ commit }, { userId }) {
    commit("setLoading", true);
    try {
      const response = await axios.get(`${process.env.VUE_APP_API_URL}/agents?account_id=${userId}`);
      commit("setAgents", response.data);
      commit("setError", null);
    } catch (error) {
      commit("setError", error.message);
    } finally {
      commit("setLoading", false);
    }
  },
  async searchAgents({ commit }, { userId, query }) {
    commit("setLoading", true);
    try {
      const response = await axios.get(`${process.env.VUE_APP_API_URL}/agents?account_id=${userId}&query=${query}`);
      commit("setAgents", response.data);
      commit("setError", null);
    } catch (error) {
      console.error("Error searching agents:", error);
      commit("setError", error.message);
    } finally {
      commit("setLoading", false);
    }
  },
  async addAgent({ commit }, payload) {
    commit("setLoading", true);
    try {
      const response = await axios.post(`${process.env.VUE_APP_API_URL}/agents`, payload);
      commit("setSelectedAgent", response.data);
      commit("setError", null);
    } catch (error) {
      console.error("Error adding agent", error);
      commit("setError", error.message);
    } finally {
      commit("setLoading", false);
    }
  },
};

const getters = {
  getAgents: state => state.agents,
  getSelectedAgent: state => state.selectedAgent,
  isLoading: state => state.loading,
  getError: state => state.error,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
