// store/modules/messageData.js
import axios from "axios";

const state = {
  incomingMessages: [],
  outgoingMessages: [],
  failedMessages: [],
  clicks: 0,
  loading: false,
  error: null,
};

const mutations = {
  setOutgoingMessages(state, messages) {
    state.outgoingMessages = messages;
  },
  setIncomingMessages(state, messages) {
    state.incomingMessages = messages;
  },
  setFailedMessages(state, messages) {
    state.failedMessages = messages;
  },
  setClicks(state, clicks) {
    state.clicks = clicks;
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
  setError(state, error) {
    state.error = error;
  },
};

const actions = {
  async fetchMessages({ commit }, { jobId }) {
    commit("setLoading", true);
    try {
      const res = await axios.get(`${process.env.VUE_APP_API_URL}/messages/count`, {
        params: {
          jobId,
        },
      });

      if (!res.data)  return;

      const { incomingMessages, outgoingMessages, failedMessages } = res.data;

      // Initialize an array with 12 elements, one for each month
      const incomingMessagesCount = Array.from({ length: 12 }, (_, i) => ({
        month: i + 1,
        messageCount: 0,
      }));
      const outgoingMessagesCount = Array.from({ length: 12 }, (_, i) => ({
        month: i + 1,
        messageCount: 0,
      }));
      const failedMessagesCount = Array.from({ length: 12 }, (_, i) => ({
        month: i + 1,
        messageCount: 0,
      }));

      // Fill the months array with the data from response
      incomingMessages.forEach((data) => {
        const monthIndex = new Date(data.month).getUTCMonth(); // Get month as a zero-based index
        incomingMessagesCount[monthIndex].messageCount = parseInt(data.messageCount, 10); // Update the corresponding month
      });

      outgoingMessages.forEach((data) => {
        const monthIndex = new Date(data.month).getUTCMonth(); // Get month as a zero-based index
        outgoingMessagesCount[monthIndex].messageCount = parseInt(data.messageCount, 10); // Update the corresponding month
      });

      failedMessages.forEach((data) => {
        const monthIndex = new Date(data.month).getUTCMonth(); // Get month as a zero-based index
        failedMessagesCount[monthIndex].messageCount = parseInt(data.messageCount, 10); // Update the corresponding month
      });

      commit("setOutgoingMessages", outgoingMessagesCount);
      commit("setIncomingMessages", incomingMessagesCount);
      commit("setFailedMessages", failedMessagesCount);

      /* Changed to the new api to fetch all at once
      const year = new Date().getFullYear();
      const outgoingPromises = [];
      const incomingPromises = [];

      for (let month = 0; month < 12; month++) {
        const startDate = new Date(year, month, 1);
        const endDate = new Date(year, month + 1, 0); // Last day of the month

        outgoingPromises.push(
          axios.get(`${process.env.VUE_APP_API_URL}/messages/count`, {
            params: {
              startDate: startDate.toISOString().split('T')[0],
              endDate: endDate.toISOString().split('T')[0],
              direction: "out",
              jobId,
            },
          })
        );

        incomingPromises.push(
          axios.get(`${process.env.VUE_APP_API_URL}/messages/count`, {
            params: {
              startDate: startDate.toISOString().split('T')[0],
              endDate: endDate.toISOString().split('T')[0],
              direction: "in",
              jobId,
            },
          })
        );
      }

      const outgoingResponses = await Promise.all(outgoingPromises);
      const incomingResponses = await Promise.all(incomingPromises);
      const outgoingMessages = outgoingResponses.map(response => response.data);
      const incomingMessages = incomingResponses.map(response => response.data);
      */

      // commit("setOutgoingMessages", outgoingMessages);
      // commit("setIncomingMessages", incomingMessages);
      commit("setError", null);
    } catch (error) {
      commit("setError", error.message);
    } finally {
      commit("setLoading", false);
    }
  },
  async fetchLinkClicks({ commit }, { link }) {
    try {
      const response = await axios.get(`${process.env.VUE_APP_API_URL}/messages/clicks`, {
        params: {
          link,
        },
      });
      commit("setClicks", response.data.total_clicks || 0);
    } catch (error) {
      commit("setError", error.message);
    } finally {
      commit("setLoading", false);
    }
  },
};

const getters = {
  getOutgoingMessages: state => state.outgoingMessages,
  getIncomingMessages: state => state.incomingMessages,
  getFailedMessages: state => state.failedMessages,
  getClicks: state => state.clicks,
  isLoading: state => state.loading,
  getError: state => state.error,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
