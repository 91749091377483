<script setup>
import { ref, watch } from "vue";
import { useStore } from "vuex";
import ArgonButton from "@/components/Core/ArgonButton.vue";
import SearchBar from "@/components/PageLayout/SearchBar.vue";

const store = useStore();

const props = defineProps({
  data: Object,
  emitValidState: Function,
  addAgent: Function,
});

const { emitValidState, addAgent } = props;

const formData = ref({ ...props.data });

// Watch for changes in agentsData and update formData accordingly
watch(
  () => props.data,
  (newValue) => {
    formData.value = newValue;
  },
  { deep: true, immeidate: true }
);

// Watch for changes in formData
watch(
  formData,
  () => {
    emitValidState(true);
  },
  { deep: true }
);

const onAssignAll = () => {
  formData.value = {
    available: [],
    assigned: [...formData.value.assigned, ...formData.value.available],
  };
  store.commit("jobData/updateAgentsStepData", formData.value);
};

const onAssign = (agent) => {
  formData.value = {
    available: formData.value.available?.filter((item) => item.id !== agent.id),
    assigned: [...formData.value.assigned, agent],
  };
  store.commit("jobData/updateAgentsStepData", formData.value);
};

const onAdd = () => {
  addAgent();
};

const onMessage = () => {
  //
};

const onTransfer = () => {
  //
};

const onLogout = () => {
  //
};

const onUnassign = (agent) => {
  formData.value = {
    available: [...formData.value.available, agent],
    assigned: formData.value.assigned?.filter((item) => item.id !== agent.id),
  };
  store.commit("jobData/updateAgentsStepData", formData.value);
};

const onUnassignAll = () => {
  formData.value = {
    available: [...formData.value.available, ...formData.value.assigned],
    assigned: [],
  };
  store.commit("jobData/updateAgentsStepData", formData.value);
};
</script>

<template>
  <div class="row">
    <div class="col-lg-6 col-12">
      <div class="d-flex align-items-center">
        <h4 class="text-dark mb-3 me-4">Available Agents</h4>
        <search-bar />
      </div>
      <div class="d-flex align-items-center px-4">
        <div>
          <argon-button class="border-radius-2xl py-2 px-3 me-4" @click="onAssignAll">
            Assign All <i class="fas fa-user-plus text-white ms-2" aria-hidden="true"></i>
          </argon-button>
          Available: {{ formData?.available?.length }}
        </div>
        <argon-button class="rounded-circle ms-auto px-3" @click="onAdd">
          +
        </argon-button>
      </div>
      <div class="table-responsive p-0 mt-4">
        <table class="table align-items-center justify-content-center mb-0">
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Name
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Email
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              ></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in formData?.available" :key="index">
              <td class="px-4">{{ item.firstName }} {{ item.lastName }}</td>
              <td class="px-4">{{ item.email }}</td>
              <td class="px-4">
                <div class="d-flex">
                  <argon-button
                    color="primary"
                    size="sm"
                    class="action-btn rounded-circle ms-auto px-2"
                    @click="onAssign(item)"
                  >
                    <i class="fas fa-user-plus text-xs text-white" aria-hidden="true"></i>
                  </argon-button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="col-lg-6 col-12">
      <div class="d-flex align-items-center">
        <h4 class="text-dark mb-3 me-4">Assigned Agents</h4>
        <search-bar />
      </div>
      <div class="d-flex align-items-center px-4">
        <div>
          <argon-button class="border-radius-2xl py-2 px-3 me-4" @click="onUnassignAll">
            Unassign All
            <i class="fas fa-user-minus text-white ms-2" aria-hidden="true"></i>
          </argon-button>
          Assigned: {{ formData?.assigned?.length }}
        </div>
      </div>
      <div class="table-responsive p-0 mt-4">
        <table class="table align-items-center justify-content-center mb-0">
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Name
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Email
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              ></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in formData?.assigned" :key="index">
              <td class="px-4">{{ item.firstName }} {{ item.lastName }}</td>
              <td class="px-4">{{ item.email }}</td>
              <td class="px-4">
                <div class="d-flex">
                  <argon-button
                    color="success"
                    size="sm"
                    class="action-btn rounded-circle ms-auto px-2"
                    @click="onMessage"
                  >
                    <i class="fas fa-comment text-xs text-white" aria-hidden="true"></i>
                  </argon-button>
                  <argon-button
                    color="warning"
                    size="sm"
                    class="action-btn rounded-circle ms-auto px-2"
                    @click="onTransfer"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="14"
                      width="12.25"
                      viewBox="0 0 448 512"
                    >
                      <path
                        fill="#ffffff"
                        d="M438.6 150.6c12.5-12.5 12.5-32.8 0-45.3l-96-96c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.7 96 32 96C14.3 96 0 110.3 0 128s14.3 32 32 32l306.7 0-41.4 41.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l96-96zm-333.3 352c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 416 416 416c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0 41.4-41.4c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-96 96c-12.5 12.5-12.5 32.8 0 45.3l96 96z"
                      />
                    </svg>
                  </argon-button>
                  <argon-button
                    color="primary"
                    size="sm"
                    class="action-btn d-flex align-items-center justify-content-center rounded-circle ms-auto px-2"
                    @click="onLogout"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="14"
                      width="14"
                      viewBox="0 0 512 512"
                      class="mx-0"
                    >
                      <path
                        fill="#ffffff"
                        d="M217.9 105.9L340.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L217.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1L32 320c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM352 416l64 0c17.7 0 32-14.3 32-32l0-256c0-17.7-14.3-32-32-32l-64 0c-17.7 0-32-14.3-32-32s14.3-32 32-32l64 0c53 0 96 43 96 96l0 256c0 53-43 96-96 96l-64 0c-17.7 0-32-14.3-32-32s14.3-32 32-32z"
                      />
                    </svg>
                  </argon-button>
                  <argon-button
                    color="dark"
                    size="sm"
                    class="action-btn rounded-circle ms-auto px-2"
                    @click="onUnassign(item)"
                  >
                    <i
                      class="fas fa-user-minus text-xs text-white"
                      aria-hidden="true"
                    ></i>
                  </argon-button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<style>
.action-btn {
  width: 34px;
  height: 34px;
}
</style>
