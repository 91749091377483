<template>
  <component
    class="dropdown"
    :is="tag"
    :class="[
      { show: isOpen },
      { dropdown: direction === 'down' },
      { dropup: direction === 'up' },
    ]"
    aria-haspopup="true"
    :aria-expanded="isOpen"
    @click="toggleDropDown"
    ref="dropdownRef"
  >
    <slot name="title">
      <a
        class="dropdown-toggle nav-link"
        :class="{ 'no-caret': hideArrow }"
        data-toggle="dropdown"
      >
        <i :class="icon"></i>
        <span class="no-icon">{{ title }}</span>
      </a>
    </slot>
    <ul
      class="dropdown-menu"
      :class="[
        { 'dropdown-menu-right': position === 'right' },
        { show: isOpen },
        menuClasses,
      ]"
    >
      <slot></slot>
    </ul>
  </component>
</template>

<script>
import { ref, onMounted, onBeforeUnmount } from "vue";

export default {
  name: "BaseDropdown",
  props: {
    direction: {
      type: String,
      default: "down",
    },
    title: {
      type: String,
      description: "Dropdown title",
    },
    icon: {
      type: String,
      description: "Icon for dropdown title",
    },
    position: {
      type: String,
      description: "Position of dropdown menu (e.g right|left)",
    },
    menuClasses: {
      type: [String, Object],
      description: "Dropdown menu classes",
    },
    hideArrow: {
      type: Boolean,
      description: "Whether dropdown arrow should be hidden",
    },
    tag: {
      type: String,
      default: "li",
      description: "Dropdown html tag (e.g div, li etc)",
    },
  },
  setup(props, { emit }) {
    const isOpen = ref(false);

    const toggleDropDown = () => {
      isOpen.value = !isOpen.value;
      emit("change", isOpen.value);
    };

    const closeDropDown = () => {
      isOpen.value = false;
      emit("change", isOpen.value);
    };

    const dropdownRef = ref(null);

    onMounted(() => {
      // Bind the click outside event
      document.addEventListener("click", handleOutsideClick);
    });

    onBeforeUnmount(() => {
      // Unbind the click outside event
      document.removeEventListener("click", handleOutsideClick);
    });

    const handleOutsideClick = (event) => {
      if (dropdownRef.value && !dropdownRef.value.contains(event.target)) {
        closeDropDown();
      }
    };

    return {
      isOpen,
      toggleDropDown,
      closeDropDown,
      dropdownRef,
    };
  },
};
</script>

<style>
.dropdown {
  list-style-type: none;
}

.dropdown .dropdown-toggle {
  cursor: pointer;
}

.dropdown .dropdown-toggle:after {
  float: right;
}
</style>
