<script setup lang="">
import { ref, watch, onMounted, computed } from "vue";
import { useStore } from "vuex";
import ArgonButton from "@/components/Core/ArgonButton.vue";
import ArgonModal from "@/components/Core/ArgonModal.vue";
import ArgonInput from "@/components/Core/ArgonInput.vue";

const {
  data: replyTemplatesData,
  emitValidState,
  emitReplyTemplatesData,
  addTemplate,
  updateTemplate,
  removeTemplate,
} = defineProps({
  data: Object,
  emitReplyTemplatesData: Function,
  emitValidState: Function,
  addTemplate: Function,
  updateTemplate: Function,
  removeTemplate: Function,
});

const store = useStore();
const templates = computed(() => store.getters["templateData/getTemplates"]);
const isMMSEnabled = computed(() => store.getters["jobData/isMMSEnabled"]);
const assignedMedia = computed(() => store.getters["mediaData/getAssignedMedia"]);

const formData = ref({ ...replyTemplatesData });
const showModal = ref(false);
const modalType = ref("Add");
const modalTitle = ref("Add Reply Template");
const footerButtonText = ref("Add");
const footerButtonDisabled = ref(true);
const segments = ref(["{title}", "{first_name}", "{mid_name}", "{last_name}"]);
const editorHtml = ref("<p></p>");
const quillEditorRef = ref(null);

onMounted(async () => {
  await loadTemplates();
});

// Watch for changes in formData
watch(
  formData,
  (newValue) => {
    formData.value = newValue;
    emitReplyTemplatesData(newValue);
    editorHtml.value = formData.value.templateText;
    footerButtonDisabled.value =
      !formData.value.templateTitle || !formData.value.templateText;
    emitValidState(true);
  },
  { deep: true }
);

watch(
  assignedMedia,
  (newValue) => {
    formData.value.templateMedia = newValue.previewUrl;
  },
  { deep: true }
);

const loadTemplates = async () => {
  await store.dispatch("templateData/fetchTemplates");
  formData.value.templates = templates;
};

const onBrowse = () => {
  store.commit("mediaData/toggleAssignMediaPanel");
};

const onAddTemplateClick = () => {
  modalType.value = "Add";
  modalTitle.value = "Add Reply Template";
  footerButtonText.value = "Add";
  showModal.value = true;
};

const closeModal = () => {
  showModal.value = false;
  formData.value.templateTitle = "";
  formData.value.templateText = "";
  formData.value.templateMedia = "";
};

const onAddTemplate = async () => {
  await store.dispatch("templateData/addTemplate", {
    title: formData.value.templateTitle,
    text: formData.value.templateText,
    media: formData.value.templateMedia,
    hasDynamicMedia: isMMSEnabled.value,
    hasDynamicMediaRendered: isMMSEnabled.value && !!formData.value.templateMedia,
    isDefalut: false,
  });

  addTemplate({
    title: formData.value.templateTitle,
    text: formData.value.templateText,
    media: formData.value.templateMedia,
  });
  formData.value.templateTitle = "";
  formData.value.templateText = "";
  formData.value.templateMedia = "";
  showModal.value = false;

  await loadTemplates();
};

const onUpdateTemplate = async () => {
  await store.dispatch("templateData/updateTemplate", {
    id: formData.value.templateId,
    title: formData.value.templateTitle,
    text: formData.value.templateText,
    media: formData.value.templateMedia,
  });

  updateTemplate({
    id: formData.value.templateId,
    title: formData.value.templateTitle,
    text: formData.value.templateText,
    media: formData.value.templateMedia,
  });
  showModal.value = false;

  await loadTemplates();
};

const onSegmentClick = (segment) => {
  // Parse the existing HTML content
  const parser = new DOMParser();
  const parsedHtml = parser.parseFromString(editorHtml.value, "text/html");

  // Find the existing <p> tag and append new text inside it
  const existingPtag = parsedHtml.querySelector("p");
  if (existingPtag) {
    existingPtag.innerHTML += segment;
    editorHtml.value = parsedHtml.body.innerHTML;
    formData.value = {
      ...formData.value,
      templateText: editorHtml.value,
    };

    /* Consider later to move cursor to the end
    // Get the Quill instance
    const quillInstance = quillEditorRef.value.getQuill();

    // Get the length of the editor content
    const length = quillInstance.getLength();

    // Remove any newline characters from segment
    const sanitizedText = segment.replace(/\n/g, "");

    // Insert text at the end of the editor
    quillInstance.insertText(length, sanitizedText.trim()); // Remove leading/trailing whitespace

    // Set the cursor position to the very end of the editor
    quillInstance.setSelection(length + sanitizedText.trim().length); // Adjusted to trim() the newText
    */
  }
};

const onEditTemplateItem = (item) => {
  formData.value.templateId = item.id;
  formData.value.templateTitle = item.title;
  formData.value.templateText = item.text;
  formData.value.templateMedia = item.media;
  modalType.value = "Edit";
  modalTitle.value = "Edit Reply Template";
  footerButtonText.value = "Update";
  showModal.value = true;
};

const onDeleteTemplateItem = async (item) => {
  await store.dispatch("templateData/deleteTemplate", item.id);
  removeTemplate(item);
  await loadTemplates();
};
</script>

<template>
  <div class="row">
    <div class="col-12 d-flex justify-content-between">
      <h2 class="text-dark mb-2">Reply Templates</h2>
      <argon-button
        color="success"
        class="rounded-circle px-4"
        @click="onAddTemplateClick"
        >+</argon-button
      >
      <argon-modal
        :showModal="showModal"
        :title="modalTitle"
        :footerButtonText="footerButtonText"
        :footerButtonDisabled="footerButtonDisabled"
        :onFooterAction="modalType === 'Add' ? onAddTemplate : onUpdateTemplate"
        @close="closeModal"
      >
        <div class="row">
          <div class="col-lg-6 col-12">
            <div class="row">
              <div class="col-12">
                <label for="template-title-input" class="form-control-label">
                  Template Title:
                </label>
                <argon-input type="text" v-model="formData.templateTitle" />
              </div>
              <div v-if="isMMSEnabled" class="col-12">
                <label class="form-control-label">Template Media:</label>
                <div class="d-flex">
                  <div class="flex-grow-1">
                    <argon-input
                      readonly
                      v-model="formData.templateMedia"
                      class="mb-0"
                    />
                  </div>
                  <div>
                    <argon-button
                      class="mx-2 px-2"
                      @click.prevent="onBrowse()"
                    >
                      Browse
                    </argon-button>
                  </div>
                  <div v-if="formData.templateMedia">
                    <div v-if="assignedMedia.mediaType === 'IMAGE'" class="media-container">
                      <img :src="formData.templateMedia" alt="Image" class="media">
                    </div>
                    <div v-else-if="assignedMedia.mediaType === 'VIDEO'" class="media-container">
                      <video controls class="media">
                        <source :src="formData.templateMedia" type="video/mp4">
                        Your browser does not support the video tag.
                      </video>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="d-block mt-4">
                  <label class="form-control-label">Template Text:</label>
                  <QuillEditor
                    ref="quillEditorRef"
                    theme="snow"
                    id="templateTextEditor"
                    v-model:content="formData.templateText"
                    content-type="html"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-12">
            <div class="row">
              <div class="col-12">
                <label class="form-control-label">Dynamic Data:</label>
                <p>
                  Click or type the tags to insert replacement text from your loaded list.
                </p>
                <div>
                  <argon-button
                    v-for="(segment, index) in segments"
                    :key="index"
                    color="primary"
                    class="px-2 py-1 me-2 mb-2 border-radius-2xl"
                    @click="onSegmentClick(segment)"
                  >
                    {{ segment }}
                  </argon-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </argon-modal>
    </div>
    <div class="col-12">
      <div class="table-responsive p-0">
        <table class="table align-items-center justify-content-center mb-0">
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Template Title
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Template Text
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Template Media
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Template Action
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in formData.templates" :key="index">
              <td class="px-4">
                <p class="text-xs font-weight-bold mb-0">
                  {{ item.title }}
                </p>
              </td>
              <td class="px-4">
                <p class="text-xs font-weight-bold mb-0">
                  {{ item.text.replace(/<\/?[^>]+(>|$)/g, "") }}
                </p>
              </td>
              <td class="px-4">
                <p class="text-xs font-weight-bold mb-0">
                  {{ item.media }}
                </p>
              </td>
              <td>
                <div class="d-flex justify-content-end">
                  <a
                    class="btn btn-link text-dark px-3 mb-0"
                    @click="onEditTemplateItem(item)"
                  >
                    <i class="fas fa-pencil-alt text-dark me-2" aria-hidden="true"></i
                    >Edit
                  </a>
                  <a
                    class="btn btn-link text-danger text-gradient px-3 mb-0"
                    @click="onDeleteTemplateItem(item)"
                  >
                    <i class="far fa-trash-alt me-2" aria-hidden="true"></i>Delete
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<style>
#templateTextEditor {
  min-height: 150px;
}
.form-control:disabled, .form-control[readonly] {
  background: transparent !important;
  opacity: 1;
}
.media {
  width: 300px;
}
</style>
