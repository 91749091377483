<script setup>
import { ref, computed, onBeforeMount, onMounted } from "vue";
import { useStore } from "vuex";
import ArgonButton from "@/components/Core/ArgonButton.vue";
import ArgonDropdown from "@/components/Core/ArgonDropdown.vue";
import SearchBar from "@/components/PageLayout/SearchBar.vue";

const body = document.getElementsByTagName("body")[0];

const store = useStore();
const phoneLists = computed(() => store.getters["phoneListData/getPhoneLists"]);
const identities = computed(() => store.getters["identityData/getIdentities"]);
const loading = computed(() => store.getters["phoneListData/isLoading"]);
const error = computed(() => store.getters["phoneListData/getError"]);
const userId = computed(() => store.getters["user/currentUser"].id);

const selectedPhoneLists = ref([]);
const selectedIdentity = ref(null);
const phoneListSearch = ref("");

onBeforeMount(() => {
  store.state.app.hideConfigButton = false;
  store.state.app.showNavbar = true;
  store.state.app.showSidenav = true;
  store.state.app.showFooter = true;
  body.classList.add("bg-gray-100");
});

onMounted(() => {
  store.dispatch("phoneListData/fetchPhoneLists", { userId: userId.value });
});

const handlePhoneListSearch = () => {
  store.dispatch("phoneListData/searchPhoneLists", {
    userId: userId.value,
    identityId: selectedIdentity.value?.identityId,
    query: phoneListSearch.value,
  });
};

const onAdd = () => {
  store.commit("jobData/toggleAddPhoneListPanel");
};

const onDelete = () => {
  phoneLists.value = phoneLists.value.filter(
    (item) => !selectedPhoneLists.value.includes(item.list_id)
  );
  selectedPhoneLists.value = [];
};

const onIdentitySelect = async (identity) => {
  selectedIdentity.value = identity;
  await store.dispatch("phoneListData/filterPhoneListByIdentity", {
    userId: userId.value,
    identityId: identity.identityId,
  });
};

const onPhoneListSelect = (selected, e) => {
  if (e.target.checked) {
    if (!selectedPhoneLists.value.find((item) => item === selected.list_id)) {
      selectedPhoneLists.value = [...selectedPhoneLists.value, selected.list_id];
    }
  } else {
    selectedPhoneLists.value = selectedPhoneLists.value.filter(
      (item) => item !== selected.list_id
    );
  }
};
</script>

<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12">
        <h2 class="text-white mb-4">Phone Lists</h2>
        <div class="card min-vh-50">
          <div class="card-body">
            <div class="d-flex align-items-center mb-4">
              <search-bar v-model="phoneListSearch" @input="handlePhoneListSearch" />
              <div class="d-flex align-items-center ms-4">
                Identity:
                <argon-dropdown class="ms-3">
                  <template v-slot:title>
                    <argon-button color="light" class="dropdown-toggle">
                      {{ selectedIdentity?.identityName || "Identity" }}
                    </argon-button>
                  </template>
                  <a
                    v-for="(identity, index) in identities"
                    :key="index"
                    class="dropdown-item d-flex align-items-center"
                    @click="onIdentitySelect(identity)"
                  >
                    <label class="mb-0">{{ identity.identityName }}</label>
                  </a>
                </argon-dropdown>
              </div>
              <argon-button
                v-if="selectedPhoneLists.length > 0"
                color="warning"
                size="md"
                class="border-radius-2xl ms-2"
                @click="onDelete()"
              >
                Delete {{ selectedPhoneLists.length }} Phone Lists
              </argon-button>
              <argon-button
                color="success"
                size="sm"
                class="action-btn rounded-circle ms-auto px-2"
                @click="onAdd()"
              >
                <i class="fas fa-plus text-xs text-white" aria-hidden="true"></i>
              </argon-button>
            </div>
            <div v-if="loading">Loading...</div>
            <div v-else-if="error">{{ error }}</div>
            <div v-else class="table-responsive p-0">
              <table class="table align-items-center justify-content-center mb-0">
                <thead>
                  <tr>
                    <th class=""></th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      List Name
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Identity
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      List Type
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Upload<br />Date
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Leads<br />Supplied
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Leads<br />Loaded
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Leads<br />Remaining
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Leads<br />Remaining
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      List<br />State
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in phoneLists" :key="index">
                    <td class="w-5 px-4">
                      <input
                        type="checkbox"
                        id="phoneListSelect"
                        name="phoneListSelect"
                        @input="(e) => onPhoneListSelect(item, e)"
                      />
                    </td>
                    <td class="w-20 px-4 text-wrap">{{ item.listName }}</td>
                    <td class="w-15 px-4">{{ item.identityId }}</td>
                    <td class="w-15 px-4">{{ item.listType }}</td>
                    <td class="w-15 px-4">{{ item.uploadDate }}</td>
                    <td class="w-5 px-4">{{ item.leadsSupplied }}</td>
                    <td class="w-5 px-4">{{ item.leadsLoaded }}</td>
                    <td class="w-5 px-4">{{ item.leadsInvalid }}</td>
                    <td class="w-5 px-4">{{ item.leadsRemaining }}</td>
                    <td class="w-10 px-4">{{ item.listState }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
