// store/modules/dncData.js
import axios from "axios";

const state = {
  dncs: [],
  loading: false,
  error: null,
  showAddDNCPanel: false,
};

const mutations = {
  setDNCs(state, dncs) {
    state.dncs = dncs;
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
  setError(state, error) {
    state.error = error;
  },
  toggleAddDNCPanel(state) {
    state.showAddDNCPanel = !state.showAddDNCPanel;
  }
};

const actions = {
  async fetchDNCs({ commit }, { userId }) {
    commit("setLoading", true);
    try {
      const response = await axios.get(`${process.env.VUE_APP_API_URL}/dncs?account_id=${userId}`);
      commit("setDNCs", response.data);
      commit("setError", null);
    } catch (error) {
      commit("setError", error.message);
    } finally {
      commit("setLoading", false);
    }
  },
  async searchDNCs({ commit }, { userId, query }) {
    commit("setLoading", true);
    try {
      const response = await axios.get(`${process.env.VUE_APP_API_URL}/dncs?account_id=${userId}&query=${query}`)
      commit("setDNCs", response.data);
      commit("setError", null);
    } catch (error) {
      console.error("Error searching agents:", error);
      commit("setError", error.message);
    } finally {
      commit("setLoading", false);
    }
  },
  async filterDNCByIdentity({ commit }, { userId, identityId }) {
    commit("setLoading", true);
    try {
      const response = await axios.get(`${process.env.VUE_APP_API_URL}/dncs?account_id=${userId}&identity_id=${identityId}`);
      commit("setDNCs", response.data);
      commit("setError", null);
    } catch (error) {
      console.error("Error filtering DNC", error);
      commit("setError", error.message);
    } finally {
      commit("setLoading", false);
    }
  },
  async addDNC({ commit }, payload) {
    commit("setLoading", true);
    try {
      await axios.post(`${process.env.VUE_APP_API_URL}/dncs`, payload);
      commit("setError", null);
    } catch (error) {
      console.error("Error adding DNC", error);
      commit("setError", error.message);
    } finally {
      commit("setLoading", false);
    }
  },
};

const getters = {
  getDNCs: state => state.dncs,
  isLoading: state => state.loading,
  getError: state => state.error,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
