<script setup>
import { ref, computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import ArgonButton from "@/components/Core/ArgonButton.vue";
import ArgonInput from "@/components/Core/ArgonInput.vue";

const router = useRouter();
const store = useStore();
const isRTL = computed(() => store.state.isRTL);
const userId = computed(() => store.getters["user/currentUser"].id);
const selectedIdentity = computed(() => store.getters["identityData/getSelectedIdentity"]);

const identityData = ref({});

const toggleAddIdentityPanel = () => store.commit("identityData/toggleAddIdentityPanel");

const onCreateIdentity = async () => {
  try {
    const payload = {
      account_id: userId.value,
      identity_name: identityData.value.name,
    };
    await store.dispatch("identityData/addIdentity", payload);
    toggleAddIdentityPanel();
    identityData.value = {};
    if (selectedIdentity.value) {
      router.push(`${router.currentRoute.value.path}/${selectedIdentity.value.identityId}`);
    }
  } catch (error) {
    console.log(error);
  }
};
</script>

<template>
  <div class="fixed-plugin">
    <div class="shadow-lg card overflow-scroll p-4">
      <div class="pb-0">
        <div class="float-start">
          <h5 class="">Identity Manager</h5>
        </div>
        <div
          class="mt-1"
          @click="toggleAddIdentityPanel"
          :class="isRTL ? 'float-start' : 'float-end'"
        >
          <button class="p-0 btn btn-link text-dark fixed-plugin-close-button">
            <i class="fa fa-close"></i>
          </button>
        </div>
      </div>
      <div>
        <div class="d-flex my-4">
          <argon-button
            class="border-radius-2xl ms-auto"
            variant="gradient"
            color="success"
            size="md"
            @click="onCreateIdentity"
          >
            Save
          </argon-button>
        </div>
        <div>
          <label for="identity-name-input" class="form-control-label">Name:</label>
          <argon-input
            id="identityName"
            type="text"
            name="identityName"
            size="md"
            v-model="identityData.name"
          />
        </div>
      </div>
    </div>
  </div>
</template>
