import store from "@/store";

<script setup lang="">
import { computed, ref, defineProps, watch, onMounted } from "vue";
import { useStore } from "vuex";
import { toE164, getStates } from "@/utils";
import ArgonButton from "@/components/Core/ArgonButton.vue";
import ArgonInput from "@/components/Core/ArgonInput.vue";
import ArgonDropdown from "@/components/Core/ArgonDropdown.vue";

const { emitValidState, emitContentData, data: contentData } = defineProps({
  data: Object,
  emitContentData: Function,
  emitValidState: Function,
});

const store = useStore();
const identities = computed(() => store.getters["identityData/getIdentities"]);
const assignedMedia = computed(() => store.getters["mediaData/getAssignedMedia"]);
const associatedSipPeers = computed(() => store.getters["jobData/getAssociatedSipPeers"]);
const siteInserviceNumbers = computed(() => store.getters["jobData/getSiteInserviceNumbers"]);
// const purchasedPhoneNumbers = computed(() => store.getters["jobData/getPurchasedPhoneNumbers"]);

const formData = ref({ ...contentData });
// const isUseMessageBuilderEnabled = ref(false);

const quickTestNumber = ref("");
// const selectedFile = ref(null);
// const userText = ref("");
const isStepValid = ref(false);
const jobAreaStates = ref([]);
// const files = ref([]);
const phoneNumberOptions = ref([
  { id: 1, name: "Existing Number" },
  { id: 2, name: "New Number" },
]);
const selectedPhoneNumberOption = ref(1);

onMounted(() => {
  jobAreaStates.value = [
    {
      name: "US National (USA)",
      code: "USA",
    },
    {
      name: "Canada National (CAN)",
      code: "CAN",
    },
    ...getStates(),
  ];
  store.dispatch("jobData/fetchAssociatedSipPeers");
  store.dispatch("jobData/fetchPurchasedPhoneNumbers");
  formData.value.existingNumbers = formData.value.existingNumbers || [];
});

// Watch for changes in formData
watch(
  formData,
  (newValue) => {
    // Check if all required fields are filled
    // isStepValid.value = Object.values(formData).every((field) => !!field);
    if (selectedPhoneNumberOption.value === 1) {
      isStepValid.value = ["identity", "jobName"].every(
        (field) => !!formData.value[field]
      );
    } else {
      isStepValid.value = ["identity", "jobName", "jobArea"].every(
        (field) => !!formData.value[field]
      );
    }

    emitValidState(isStepValid.value);
    formData.value = newValue;
    emitContentData(newValue);
  },
  { deep: true }
);

watch(
  assignedMedia,
  (newValue) => {
    formData.value.initialTemplateMedia = newValue.previewUrl;
  },
  { deep: true }
);

const onIdentitySelect = (identity) => {
  formData.value.identity = identity;
  const site = associatedSipPeers.value?.find(item => item.SiteName == identity.identityName);

  if (site) {
    store.dispatch("jobData/fetchSiteInserviceNumbers", { siteId: site.SiteId });
  }
};

const selectPhoneNumberOption = (option) => {
  selectedPhoneNumberOption.value = option.id;

  if (option.id === 1) {
    store.dispatch("jobData/fetchPurchasedPhoneNumbers");
  }
};

// const onExistingNumberSelect = (existingNumber) => {
//   const index = formData.value.existingNumbers?.indexOf(existingNumber);
//   if (index > -1) {
//     formData.value.existingNumbers?.splice(index, 1);
//   } else {
//     formData.value.existingNumbers?.push(existingNumber);
//   }
// };

const onExistingNumberSelect = (existingNumber) => {
  formData.value.existingNumber = existingNumber;
  formData.value.adminPhone = existingNumber;
};

const onQuickTestClick = async () => {
  // Parse the HTML content to plain text
  const parser = new DOMParser();
  const doc = parser.parseFromString(formData.value.initialTemplateMessage, "text/html");
  const formattedPhoneNumber = toE164(quickTestNumber.value, "1");

  // Get all paragraphs from the parsed document
  const paragraphs = Array.from(doc.body.querySelectorAll("p"));

  // Initialize an array to store formatted paragraphs
  const formattedParagraphs = [];

  // Iterate through each paragraph, add its text content to the array with line breaks
  paragraphs.forEach(paragraph => {
    formattedParagraphs.push(paragraph.textContent);
  });

  // Join formatted paragraphs with line breaks
  const testMessage = formattedParagraphs.join("\n");

  if (formattedPhoneNumber) {
    await store.dispatch("jobData/testQuick", {
      to: [formattedPhoneNumber],
      from: formData.value.adminPhone,
      text: testMessage,
      media: formData.value.isMMSEnabled ? formData.value.initialTemplateMedia : null
    });
  }
};

// const onHandleFileUpload = () => {
//   // Update selectedFile with the chosen file
//   selectedFile.value = event.target.files[0];
// };

// const onUploadFileClick = () => {};

const onMMSEnabledClick = () => {
  formData.value.isMMSEnabled = !formData.value.isMMSEnabled;
  // formData.value.initialTemplateMedia = "";
};

const onBrowse = () => {
  store.commit("mediaData/setSelectedIdentity", formData.value.identity);
  store.commit("mediaData/toggleAssignMediaPanel");
};

// const onFileUpload = ($event) => {
//   const target = $event?.EventTarget;
//   if (target && EventTarget.files) {
//     const fileList = Array.from(EventTarget.files);
//     const filesArray = fileList.map((file) => ({
//       file: file,
//       title: file.name,
//     }));
//     files.value = filesArray;
//   }
// };

// const onUseMessageBuilderEnabledClick = () => {};

const onJobAreaStateSelect = (jobArea) => {
  formData.value.jobArea = jobArea;
  formData.value.allAreaCodes = [];
};

const onAreaCodeSelect = (areaCode) => {
  const index = formData.value.allAreaCodes?.indexOf(areaCode);
  if (index > -1) {
    formData.value.allAreaCodes?.splice(index, 1);
  } else {
    formData.value.allAreaCodes?.push(areaCode);
  }
};
</script>

<template>
  <div class="row">
    <div class="col-lg-6 col-12">
      <div class="row">
        <div class="col-12">
          <!-- <label for="identity-input" class="form-control-label">Identity:</label>
          <argon-input type="text" v-model="formData.identity" /> -->
          <label>Identity:</label>
          <argon-dropdown class="">
            <template v-slot:title>
              <argon-button color="light" class="dropdown-toggle">
                {{ formData.identity?.identityName || "Identity" }}
              </argon-button>
            </template>
            <a
              v-for="(identity, index) in identities"
              :key="index"
              class="dropdown-item d-flex align-items-center"
              @click="onIdentitySelect(identity)"
            >
              <label class="mb-0">{{ identity.identityName }}</label>
            </a>
          </argon-dropdown>
        </div>
        <div class="col-12">
          <label for="job-name-input" class="form-control-label">Job Name:</label>
          <argon-input type="text" v-model="formData.jobName" />
        </div>
        <div class="col-12">
          <div v-for="option in phoneNumberOptions" :key="option.id">
            <label>
              <input 
                  type="radio" 
                  :value="option.id" 
                  :checked="selectedPhoneNumberOption === option.id"
                  @change="selectPhoneNumberOption(option)"
              />
              {{ option.name }}
            </label>
          </div>
        </div>
        <div v-if="selectedPhoneNumberOption === 1">
          <argon-dropdown class="">
            <template v-slot:title>
              <argon-button color="light" class="dropdown-toggle">
                {{ formData.existingNumber || "Select Phone Number" }}
              </argon-button>
            </template>
            <a
              v-for="(phoneNumber, index) in siteInserviceNumbers"
              :key="index"
              class="dropdown-item d-flex align-items-center"
              @click="onExistingNumberSelect(phoneNumber)"
            >
              <label class="mb-0">{{ phoneNumber }}</label>
            </a>
            <!-- Changed to use site inservice numbers instead of purchased numbers
            <a
              v-for="(phoneNumber, index) in purchasedPhoneNumbers"
              :key="index"
              class="dropdown-item d-flex align-items-center"
              @click="onExistingNumberSelect(phoneNumber.FullNumber)"
            >
              <label class="mb-0">{{ phoneNumber.FullNumber }}</label>
            </a> -->
            <!-- <template v-slot:title>
              <argon-button color="light" class="w-100 dropdown-toggle">
                {{ formData.existingNumbers?.join(", ") }}
              </argon-button>
            </template>
            <a
              v-for="(existingNumber, index) in purchasedPhoneNumbers"
              :key="index"
              class="dropdown-item d-flex align-items-center"
            >
              <input
                type="checkbox"
                :id="'existingNumber-' + index"
                value="formData?.existingNumbers.includes(existingNumber)"
                @change="onExistingNumberSelect(existingNumber?.FullNumber)"
              />
              <label class="mb-0">{{ existingNumber?.FullNumber }}</label>
            </a> -->
          </argon-dropdown>
        </div>
        <div v-if="selectedPhoneNumberOption === 2" class="col-12">
          <label for="job-area-input" class="form-control-label">Job Area:</label>
          <p>
            Your numbers (DIDs) for your agents will be automatically leased to you based
            on the state (and area codes) you select. If there are no available numbers
            where you have selected, the next best available number from the state, then
            country will be chosen. If US or Canada National is selected, the numbers will
            be leased from random area codes in that country.
          </p>
          <!-- <argon-input type="text" v-model="formData.jobArea" /> -->
          <argon-dropdown class="">
            <template v-slot:title>
              <argon-button color="light" class="w-100 dropdown-toggle">
                {{ formData?.jobArea?.name || "US National (USA)" }}
              </argon-button>
            </template>
            <a
              v-for="(jobArea, index) in jobAreaStates"
              :key="index"
              class="dropdown-item d-flex align-items-center"
              @click="onJobAreaStateSelect(jobArea)"
            >
              <label class="mb-0">{{ jobArea?.name }}</label>
            </a>
          </argon-dropdown>
        </div>
        <div v-if="selectedPhoneNumberOption === 2 && formData.jobArea && !['USA', 'CAN'].includes(formData.jobArea?.code)" class="col-12">
          <label for="all-area-codes-input" class="form-control-label">
            All Area Codes:
          </label>
          <!-- <argon-input type="text" v-model="allAreaCodes" /> -->
          <argon-dropdown class="">
            <template v-slot:title>
              <argon-button color="light" class="w-100 dropdown-toggle">
                {{ formData.allAreaCodes?.join(", ") }}
              </argon-button>
            </template>
            <a
              v-for="(areaCode, index) in formData.jobArea?.areaCodes"
              :key="index"
              class="dropdown-item d-flex align-items-center"
            >
              <input
                type="checkbox"
                :id="'areaCode-' + index"
                value="formData?.allAreaCodes.includes(areaCode)"
                @change="onAreaCodeSelect(areaCode)"
              />
              <label class="mb-0">{{ areaCode }}</label>
            </a>
          </argon-dropdown>
        </div>
        <!-- <div class="col-12">
          <label for="upload-file-input" class="form-control-label">Upload File:</label>
          <div class="row">
            <div class="col-6">
              <argon-input
                type="file"
                @change="onHandleFileUpload"
                accept="image/*, video/*"
              />
            </div>
            <div class="col-6 d-flex justify-content-end align-items-start">
              <argon-button
                color="success"
                size="sm"
                class="ms-2"
                @click="onUploadFileClick"
                >Upload File</argon-button
              >
            </div>
          </div>
        </div>
        <div class="col-12">
          <label for="user-text-input" class="form-control-label">User Text:</label>
          <argon-input type="text" v-model="userText" />
        </div> -->
      </div>
    </div>
    <div class="col-lg-6 col-12">
      <div class="d-block mb-4">
        <h6 class="mb-0">MMS Enabled:</h6>
        <div class="form-check form-switch my-auto">
          <input
            class="mt-1 form-check-input"
            type="checkbox"
            id="mmsEnabled"
            :checked="formData.isMMSEnabled"
            @click="onMMSEnabledClick"
          />
        </div>
      </div>
      <!-- Consider later
      <div class="d-block">
        <h6 class="mb-0">Use Message Builder:</h6>
        <div class="form-check form-switch my-auto">
          <input
            class="mt-1 form-check-input"
            type="checkbox"
            id="useMessageBuilder"
            :checked="isUseMessageBuilderEnabled"
            @click="onUseMessageBuilderEnabledClick"
          />
        </div>
      </div> -->
      <div v-if="formData.isMMSEnabled" class="d-block mt-4">
        <label class="form-control-label">Initial Template Media:</label>
        <!-- <argon-input
          ref="file"
          type="file"
          id="fileInput"
          @change="(e) => onFileUpload(e)"
          accept="image/*, video/*"
          :multiple="true"
        /> -->
        <div class="d-flex">
          <div class="flex-grow-1">
            <argon-input
              readonly
              v-model="formData.initialTemplateMedia"
              class="mb-0"
            />
          </div>
          <div>
            <argon-button
              class="mx-2 px-2"
              :disabled="!formData.identity"
              @click.prevent="onBrowse()"
            >
              Browse
            </argon-button>
          </div>
          <div v-if="formData.initialTemplateMedia">
            <div v-if="assignedMedia.mediaType === 'IMAGE'" class="media-container">
              <img :src="formData.initialTemplateMedia" alt="Image" class="media">
            </div>
            <div v-else-if="assignedMedia.mediaType === 'VIDEO'" class="media-container">
              <video controls class="media">
                <source :src="formData.initialTemplateMedia" type="video/mp4">
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
        <div v-if="!formData.identity" class="text-red">You must select an identity to assign a media</div>
      </div>
      <div class="d-block mt-4">
        <label class="form-control-label">Initial Template Message:</label>
        <QuillEditor
          theme="snow"
          id="initialTemplateMessageEditor"
          v-model:content="formData.initialTemplateMessage"
          content-type="html"
        />
      </div>
      <div class="d-block mt-4">
        <label for="quick-test-number-input" class="form-control-label">
          Quick Test Number:
        </label>
        <div class="row">
          <div class="col-6">
            <argon-input type="text" v-model="quickTestNumber" />
          </div>
          <div class="col-6 d-flex justify-content-end align-items-start">
            <argon-button
              color="success"
              size="sm"
              class="ms-2"
              @click="onQuickTestClick"
            >
              Quick Test
            </argon-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
#initialTemplateMessageEditor {
  min-height: 185px;
}
.form-control:disabled, .form-control[readonly] {
  background: transparent !important;
  opacity: 1;
}
.text-red {
  color: red;
}
.media {
  width: 300px;
}
</style>
