<script setup>
import { onMounted, computed, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import ArgonButton from "@/components/Core/ArgonButton.vue";
import ArgonInput from "@/components/Core/ArgonInput.vue";

const store = useStore();
const router = useRouter();
const jobId = computed(() => router.currentRoute.value.params.id);
const recipients = computed(() => store.getters["chatData/getRecipients"]);
const selectedRecipient = computed(() => store.getters["chatData/getSelectedRecipient"]);
const chats = computed(() => store.getters["chatData/getChats"]);

const replyText = ref("");
let currentPage = ref(1);
const perPage = ref(12);

const paginatedRecipients = computed(() => {
  const start = (currentPage.value - 1) * perPage.value;
  const end = start + perPage.value;
  return recipients?.value?.slice(start, end);
});

const totalPages = computed(() => {
  return Math.ceil(recipients?.value?.length / perPage.value);
});

onMounted(async () => {
  await store.dispatch("chatData/fetchRecipients", { jobId: jobId.value });
});

const nextPage = () => {
  if (currentPage.value < totalPages.value) {
    currentPage.value++;
  }
};

const prevPage = () => {
  if (currentPage.value > 1) {
    currentPage.value--;
  }
};

const onRecipientSelect = async (recipient) => {
  store.commit("chatData/setChats", []);
  store.commit("chatData/setSelectedRecipient", recipient);
  store.dispatch("chatData/fetchChatHistory", { jobId: jobId.value, recipient });
};

const sendText = async () => {
  await store.dispatch("jobData/testQuick", {
    jobId: jobId.value,
    to: [selectedRecipient.value],
    text: replyText.value,
    tag: "reply from dashboard"
  });
  await store.dispatch("chatData/fetchChatHistory", {
    jobId: jobId.value,
    recipient: selectedRecipient.value,
  });
  replyText.value = "";
};
</script>

<template>
  <div class="py-4 container-fluid chat-details-container">
    <div class="row h-100">
      <div class="col-12 d-flex flex-column h-100">
        <h2 class="text-white mb-2">Chat Details</h2>
        <div class="row flex-grow-1">
          <div class="col-3">
            <div class="card min-vh-50 h-100">
              <div class="card-header">
                Chat List
              </div>
              <div class="card-body">
                <div class="row">
                  <div
                    class="col-12 p-2 cursor-pointer"
                    v-for="(item, index) in paginatedRecipients"
                    :key="index"
                    @click.prevent="onRecipientSelect(item)"
                  >
                    <i class="fas fa-user"></i>
                    {{ item }}
                  </div>
                </div>
                <div class="d-flex justify-content-between pagination-controls">
                  <argon-button
                    @click="prevPage"
                    :disabled="currentPage === 1"
                    class="pagination-button"
                  >
                    <i class="fas fa-chevron-left"></i>
                  </argon-button>
                  <span class="pagination-info">{{ currentPage }} / {{ totalPages }}</span>
                  <argon-button
                    @click="nextPage"
                    :disabled="currentPage === totalPages"
                    class="pagination-button"
                  >
                    <i class="fas fa-chevron-right"></i>
                  </argon-button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-9">
            <div class="card min-vh-50 h-100">
              <div class="card-header">
                <i class="fas fa-user"></i>
                {{ selectedRecipient || "" }}
              </div>
              <div class="card-body">
                <div class="chat-box">
                  <div
                    v-for="(chat, index) in chats"
                    :key="index"
                    :class="{
                      'chat-message': true,
                      'chat-right': chat.to === selectedRecipient,
                      'chat-left': chat.from === selectedRecipient}
                    "
                    v-html="chat.message"
                  >
                  </div>
                </div>
              </div>
              <div class="card-footer chat-input">
                <argon-input
                  class="send-text"
                  v-model="replyText"
                />
                <argon-button
                  color="success"
                  size="sm"
                  class="send-button"
                  @click.prevent="sendText()"
                >
                  Send
                </argon-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.chat-details-container {
  height: 100vh;
}

.chat-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
}

.pagination-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}

.pagination-info {
  margin: 0 10px;
  font-weight: bold;
}

.pagination-button {
  padding: 5px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.pagination-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.pagination-button:not(:disabled):hover {
  background-color: #0056b3;
}

.chat-message {
  width: 80%;
  padding: 10px;
  margin: 5px;
  border-radius: 10px;
}

.chat-right {
  background-color: royalblue;
  color: white;
  padding: 10px;
  margin: 5px;
  border-radius: 10px;
  align-self: flex-end;
}

.chat-left {
  background-color: red;
  color: white;
  padding: 10px;
  margin: 5px;
  border-radius: 10px;
  align-self: flex-start;
}

.chat-input {
  width: 100%;
  display: flex;
  align-items: center;
}

.chat-input .send-text {
  flex-grow: 1;
  margin-bottom: 0;
}

.chat-input .send-button {
  margin-left: 10px;
}
</style>
