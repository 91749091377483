<template>
  <div class="form-group mb-0">
    <div :class="['input-group', { 'focus-border': isFocused }]">
      <span :class="['input-group-text', { 'focus-border': isFocused }]">
        <i class="fas fa-search"></i>
      </span>
      <input
        :id="id"
        :type="type"
        class="form-control"
        :class="getClasses(size, success, error)"
        :name="name"
        :value="modelValue"
        :placeholder="placeholder"
        :isRequired="isRequired"
        @input="emit('update:modelValue', $event.target.value)"
        @focus="handleFocus"
        @blur="handleBlur"
      />
      <span :class="['input-group-text', { 'focus-border': isFocused }]">
        <i class="fas fa-times-circle" @click="clearInput"></i>
      </span>
    </div>
  </div>
</template>

<script setup>
import { defineEmits, ref } from "vue";

const emit = defineEmits(["update:modelValue"]);

defineProps({
  size: {
    type: String,
    default: "default",
  },
  success: {
    type: Boolean,
    default: false,
  },
  error: {
    type: Boolean,
    default: false,
  },
  icon: {
    type: String,
    default: "",
  },
  iconDir: {
    type: String,
    default: "",
  },
  name: {
    type: String,
    default: "",
  },
  id: {
    type: String,
    default: "",
  },
  modelValue: {
    type: String,
    default: "",
  },
  placeholder: {
    type: String,
    default: "",
  },
  type: {
    type: String,
    default: "text",
  },
  isRequired: {
    type: Boolean,
    default: false,
  },
});
let isFocused = ref(false);

const getClasses = (size, success, error) => {
  let sizeValue, isValidValue;

  sizeValue = size ? `form-control-${size}` : null;

  if (error) {
    isValidValue = "is-invalid";
  } else if (success) {
    isValidValue = "is-valid";
  } else {
    isValidValue = "";
  }

  return `${sizeValue} ${isValidValue}`;
};

const clearInput = () => {
  emit("update:modelValue", "");
};
const handleFocus = () => {
  isFocused.value = true;
};
const handleBlur = () => {
  isFocused.value = false;
};
</script>

<style scoped>
.input-group.focus-border {
  border-color: var(--bg-gradient-primary) !important;
}

.input-group.focus-border input.form-control {
  border: 1px solid var(--bs-primary) !important;
  border-radius: 4px; /* Optional: Add border-radius for a rounded border */
}

.input-group:not(.focus-border) input.form-control {
  border: 1px solid var(--bs-teal); /* Set transparent border */
  border-radius: 4px; /* Optional: Add border-radius for a rounded border */
  border-width: 1px; /* Ensure consistent input box size */
}

.input-group-text.focus-border {
  border: 1px solid var(--bs-primary) !important;
}

.input-group:not(.focus-border) .input-group-text {
  border: 1px solid var(--bs-teal); /* Set transparent border */
  border-radius: 4px; /* Optional: Add border-radius for a rounded border */
  border-width: 1px; /* Ensure consistent input box size */
}
</style>
