<script setup>
import { computed } from "vue";
import { useRoute , useRouter} from "vue-router";
import { useStore } from "vuex";

import SidenavItem from "./SidenavItem.vue";

const store = useStore();
const router = useRouter();
const isRTL = computed(() => store.state.app.isRTL);

const getRoute = () => {
  const route = useRoute();
  const routeArr = route.path.split("/");
  return routeArr[1];
};

const onLogout = () => {
  store.dispatch("user/logout");
  router.push("/signin");
};
</script>
<template>
  <div class="collapse navbar-collapse w-auto h-auto h-100" id="sidenav-collapse-main">
    <ul class="navbar-nav">
      <li class="nav-item">
        <sidenav-item
          to="/media"
          :class="getRoute() === 'media' ? 'active' : ''"
          :navText="!isRTL && 'Media'"
        >
          <template v-slot:icon>
            <i class="fab fa-medium-m text-primary text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <li class="nav-item">
        <sidenav-item
          to="/dnc"
          :class="getRoute() === 'dnc' ? 'active' : ''"
          :navText="!isRTL && 'DNC Manager'"
        >
          <template v-slot:icon>
            <i class="fas fa-phone-slash text-warning text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <li class="nav-item">
        <sidenav-item
          to="/phonelist"
          :class="getRoute() === 'phonelist' ? 'active' : ''"
          :navText="!isRTL && 'Phone Lists'"
        >
          <template v-slot:icon>
            <i class="fas fa-list text-success text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <li class="nav-item">
        <sidenav-item
          to="/agent"
          :class="getRoute() === 'agent' ? 'active' : ''"
          :navText="!isRTL && 'P2P SMS Agents'"
        >
          <template v-slot:icon>
            <i class="fas fa-users text-info text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <li class="nav-item">
        <sidenav-item
          to="/identity"
          :class="getRoute() === 'identity' ? 'active' : ''"
          :navText="!isRTL && 'Identities'"
        >
          <template v-slot:icon>
            <i class="fas fa-address-card text-danger text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <li class="nav-item">
        <sidenav-item
          to="/report"
          :class="getRoute() === 'report' ? 'active' : ''"
          :navText="!isRTL && 'Reports'"
        >
          <template v-slot:icon>
            <i class="fas fa-chart-line text-success text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <li class="nav-item">
        <sidenav-item
          to="/chats"
          :class="getRoute() === 'chats' ? 'active' : ''"
          :navText="!isRTL && 'Chat History'"
        >
          <template v-slot:icon>
            <i class="far fa-comments text-primary text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <li class="nav-item" @click="onLogout()">
        <sidenav-item to="/" :navText="!isRTL && 'Logout'">
          <template v-slot:icon>
            <i class="fas fa-sign-out-alt text-danger text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>
    </ul>
  </div>
</template>
