// store/modules/app.js
const state = {
  hideConfigButton: false,
  isPinned: false,
  showConfig: false,
  sidebarType: "bg-white",
  isRTL: false,
  mcolor: "",
  darkMode: false,
  isNavFixed: false,
  isAbsolute: false,
  showNavs: true,
  showSidenav: true,
  showNavbar: true,
  showFooter: true,
  showMain: true,
  layout: "default",
};

const mutations = {
  toggleConfigurator(state) {
    state.showConfig = !state.showConfig;
  },
  sidebarMinimize(state) {
    let sidenav_show = document.querySelector("#app");
    if (state.isPinned) {
      sidenav_show.classList.add("g-sidenav-hidden");
      sidenav_show.classList.remove("g-sidenav-pinned");
      state.isPinned = false;
    } else {
      sidenav_show.classList.add("g-sidenav-pinned");
      sidenav_show.classList.remove("g-sidenav-hidden");
      state.isPinned = true;
    }
  },
  sidebarType(state, payload) {
    state.sidebarType = payload;
  },
  navbarFixed(state) {
    if (state.isNavFixed === false) {
      state.isNavFixed = true;
    } else {
      state.isNavFixed = false;
    }
  },
};

const actions = {
  toggleSidebarColor({ commit }, payload) {
    commit("sidebarType", payload);
  },
};

const getters = {
  //
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
