<script setup>
import { onMounted, ref, watch } from "vue";
import ArgonButton from "@/components/Core/ArgonButton.vue";
import ArgonDropdown from "@/components/Core/ArgonDropdown.vue";
import TimeRangeMinMaxSlider from "./TimeRangeMinMaxSlider.vue";
import { debounce, getDecimalTime, getFormattedTime, getFormatted24hTime } from "@/utils";

const { emitDeliveryScheduleData, data: deliveryScheduleData } = defineProps({
  data: Object,
  emitDeliveryScheduleData: Function,
});

const timezones = [
  "Local Relative to Contact",
  "US/Eastern",
  "Canada/Atlantic",
  "Canada/Newfoundland",
  "US/Alaska",
  "US/Aleutian",
  "US/Arizona",
  "US/Central",
  "US/East-Indiana",
  "US/Hawaii",
  "US/Indiana-Starke",
  "US/Michigan",
  "US/Mountain",
  "US/Pacific",
  "US/Samoa",
];
const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

const formData = ref({ ...deliveryScheduleData });
const startDay = ref(0);
const endDay = ref(-1);
const sliderMin = ref(8);
const sliderMax = ref(21);
const displayRanges = ref([]);

onMounted(() => {
  updateScheduleSlider();
});

// Function to calculate the number of days between two dates, inclusive
const calculateDaysBetweenDates = (sDate, eDate) => {
  // Parse the input dates
  const start = new Date(sDate);
  const end = new Date(eDate);

  // Calculate the difference in time (in milliseconds)
  const timeDifference = end - start;

  // Convert time difference from milliseconds to days and add 1 to include both start and end dates
  const dayDifference = timeDifference / (1000 * 60 * 60 * 24) + 1;

  return dayDifference;
};

watch(
  formData,
  (newValue) => {
    if (newValue.startDate && newValue.endDate) {
      mapDays(calculateDaysBetweenDates(newValue.startDate, newValue.endDate));
    }
    // formData.value = newValue;
    // updateScheduleSlider();
    emitDeliveryScheduleData(newValue);
  },
  { deep: true }
);

watch(
  displayRanges,
  debounce((newValue) => {
    updateDeliveryScheduleData(newValue);
  }, 500),
  { deep: true }
);

const updateScheduleSlider = () => {
  displayRanges.value = [
    {
      start: getDecimalTime(formData.value.sunStart),
      end: getDecimalTime(formData.value.sunEnd),
    },
    {
      start: getDecimalTime(formData.value.monStart),
      end: getDecimalTime(formData.value.monEnd),
    },
    {
      start: getDecimalTime(formData.value.tueStart),
      end: getDecimalTime(formData.value.tueEnd),
    },
    {
      start: getDecimalTime(formData.value.wedStart),
      end: getDecimalTime(formData.value.wedEnd),
    },
    {
      start: getDecimalTime(formData.value.thuStart),
      end: getDecimalTime(formData.value.thuEnd),
    },
    {
      start: getDecimalTime(formData.value.friStart),
      end: getDecimalTime(formData.value.friEnd),
    },
    {
      start: getDecimalTime(formData.value.satStart),
      end: getDecimalTime(formData.value.satEnd),
    },
  ];
};

const mapDays = (dayCount) => {
  if (dayCount >= 7) {
    startDay.value = 0;
    endDay.value = 6;
  } else {
    startDay.value = new Date(formData.value.startDate).getDay();
    endDay.value = Math.round(startDay.value + dayCount - 1);
  }
};

const updateDeliveryScheduleData = (updated) => {
  formData.value = {
    ...formData.value,
    sunStart: getFormatted24hTime(updated[0].start),
    sunEnd: getFormatted24hTime(updated[0].end),
    monStart: getFormatted24hTime(updated[1].start),
    monEnd: getFormatted24hTime(updated[1].end),
    tueStart: getFormatted24hTime(updated[2].start),
    tueEnd: getFormatted24hTime(updated[2].end),
    wedStart: getFormatted24hTime(updated[3].start),
    wedEnd: getFormatted24hTime(updated[3].end),
    thuStart: getFormatted24hTime(updated[4].start),
    thuEnd: getFormatted24hTime(updated[4].end),
    friStart: getFormatted24hTime(updated[5].start),
    friEnd: getFormatted24hTime(updated[5].end),
    satStart: getFormatted24hTime(updated[6].start),
    satEnd: getFormatted24hTime(updated[6].end),
  };
};
</script>

<template>
  <div class="row">
    <div class="col-12">
      <h2 class="text-dark mb-2">Delivery Schedule</h2>
    </div>
    <div class="col-12">
      <div class="row">
        <div class="col-3">
          <label class="form-control-label">Start Date:</label>
          <VueDatePicker v-model="formData.startDate" format="yyyy-MM-dd"></VueDatePicker>
        </div>
        <div class="col-3">
          <label class="form-control-label">End Date:</label>
          <VueDatePicker v-model="formData.endDate" format="yyyy-MM-dd"></VueDatePicker>
        </div>
      </div>
      <div class="border mt-4">
        <div class="row p-4">
          <div class="col-3 ps-2 pe-2 border-end">
            <argon-dropdown class="">
              <template v-slot:title>
                <argon-button color="light" class="dropdown-toggle border-radius-2xl">
                  {{ formData.scheduleTimezone }}
                </argon-button>
              </template>
              <a
                v-for="(tz, index) in timezones"
                :key="index"
                class="dropdown-item d-flex align-items-center"
                @click="formData.scheduleTimezone = tz"
              >
                <label class="mb-0">{{ tz }}</label>
              </a>
            </argon-dropdown>
          </div>
          <div class="col-9">
            <div
              v-for="(dateRange, index) in displayRanges"
              :key="index"
              class="row align-items-center"
              :class="{
                'd-none':
                  (endDay < 7 && index < startDay) ||
                  (endDay > 6 && index < startDay && index > endDay - 7) ||
                  index > endDay,
              }"
            >
              <div class="col-1">
                <argon-button color="light" class="border-radius-2xl px-3">
                  x
                </argon-button>
              </div>
              <div class="col-1">{{ daysOfWeek[index] }}</div>
              <div class="col-10">
                <div>
                  <span>{{ getFormattedTime(dateRange.start) }}</span>
                  to
                  <span>{{ getFormattedTime(dateRange.end) }}</span>
                  <time-range-min-max-slider
                    :min="sliderMin"
                    :max="sliderMax"
                    v-model:min-value="dateRange.start"
                    v-model:max-value="dateRange.end"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
