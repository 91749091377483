<script setup>
import { onBeforeUnmount, onBeforeMount } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

import Navbar from "@/components/PageLayout/Navbar.vue";
import AppFooter from "@/components/PageLayout/Footer.vue";
import ArgonInput from "@/components/Core/ArgonInput.vue";
import ArgonCheckbox from "@/components/Core/ArgonCheckbox.vue";
import ArgonButton from "@/components/Core/ArgonButton.vue";
const body = document.getElementsByTagName("body")[0];

const store = useStore();
const router = useRouter();

let userName = "";
let email = "";
let password = "";

onBeforeMount(() => {
  store.state.app.hideConfigButton = true;
  store.state.app.showNavbar = false;
  store.state.app.showSidenav = false;
  store.state.app.showFooter = false;
  body.classList.remove("bg-gray-100");
});
onBeforeUnmount(() => {
  store.state.app.hideConfigButton = false;
  store.state.app.showNavbar = true;
  store.state.app.showSidenav = true;
  store.state.app.showFooter = true;
  body.classList.add("bg-gray-100");
});

const signUp = async () => {
  try {
    await store.dispatch("user/signup", {
      userName,
      email,
      password,
    });
    // Redirect to home page or display success message
    router.push("/");
  } catch (error) {
    // Handle signup error (display error message or log it)
    console.error("Signup error:", error);
  }
};
</script>

<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar isBtn="bg-gradient-light" />
      </div>
    </div>
  </div>
  <main class="main-content mt-0">
    <div
      class="page-header align-items-start min-vh-50 pt-5 pb-11 m-3 border-radius-lg"
      style="
        background-image: url(&quot;https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/signup-cover.jpg&quot;);
        background-position: top;
      "
    >
      <span class="mask bg-gradient-dark opacity-6"></span>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-5 text-center mx-auto">
            <h1 class="text-white mb-2 mt-5">Welcome!</h1>
            <p class="text-lead text-white">
              Create your account on this amazing texting platform!
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row mt-lg-n10 mt-md-n11 mt-n10 justify-content-center">
        <div class="col-xl-4 col-lg-5 col-md-7 mx-auto">
          <div class="card z-index-0">
            <div class="card-header text-center pt-4">
              <h5>Register</h5>
            </div>
            <div class="card-body">
              <form role="form" @submit.prevent="signUp">
                <argon-input
                  id="userName"
                  type="text"
                  placeholder="User Name"
                  aria-label="User Name"
                  name="userName"
                  size="lg"
                  v-model="userName"
                />
                <argon-input
                  id="email"
                  type="email"
                  placeholder="Email"
                  aria-label="Email"
                  name="email"
                  size="lg"
                  v-model="email"
                />
                <argon-input
                  id="password"
                  type="password"
                  placeholder="Password"
                  aria-label="Password"
                  name="password"
                  size="lg"
                  v-model="password"
                />
                <argon-checkbox checked>
                  <label class="form-check-label" for="flexCheckDefault">
                    I agree the
                    <a href="javascript:;" class="text-dark font-weight-bolder">
                      Terms and Conditions
                    </a>
                  </label>
                </argon-checkbox>
                <div class="text-center">
                  <argon-button
                    fullWidth
                    color="dark"
                    variant="gradient"
                    class="my-4 mb-2"
                  >
                    Sign up
                  </argon-button>
                </div>
                <p class="text-sm mt-3 mb-0">
                  Already have an account?
                  <router-link to="/signin" class="text-dark font-weight-bolder"
                    >Sign in</router-link
                  >
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <app-footer />
</template>
