<script setup>
import { ref, computed, onBeforeMount, onMounted } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import ArgonButton from "@/components/Core/ArgonButton.vue";
import ArgonInput from "@/components/Core/ArgonInput.vue";
import ArgonDropdown from "@/components/Core/ArgonDropdown.vue";
import TDLCProfileRegistrationModal from "./TDLCProfileRegistrationModal.vue";

const body = document.getElementsByTagName("body")[0];

const route = useRoute();
const router = useRouter();
const identityId = computed(() => route.params.id);
const store = useStore();
const selectedIdentity = computed(
  () => store.getters["identityData/getSelectedIdentity"]
);
const selectedTdlc = computed(() => store.getters["identityData/getSelectedTdlc"]);

const showModal = ref(false);
const organizationInfo = ref({});
const errors = ref({});

const entityTypes = [
  "PRIVATE_PROFIT",
  "PUBLIC_PROFIT",
  "NON_PROFIT",
  "GOVERNMENT",
  "SOLE_PROPRIETOR",
];

const verticals = [
  "REAL_ESTATE",
  "HEALTHCARE",
  "ENERGY",
  "ENTERTAINMENT",
  "RETAIL",
  "AGRICULTURE",
  "INSURANCE",
  "EDUCATION",
  "HOSPITALITY",
  "FINANCIAL",
  "GAMBLING",
  "CONSTRUCTION",
  "NGO",
  "MANUFACTURING",
  "GOVERNMENT",
  "TECHNOLOGY",
  "COMMUNICATION",
];

const isFormValid = computed(() => {
  const organizationInfoFields = Object.keys(organizationInfo.value);
  // Check if the organizationInfo object is empty
  if (organizationInfoFields.length === 0) {
    return false;
  }
  return (
    Object.values(errors.value).every((error) => !error) &&
    Object.keys(organizationInfo.value).every(
      (field) =>
        field === "displayName" ||
        field === "sample1" ||
        field === "sample2" ||
        field === "isPolitical" ||
        organizationInfo.value[field]
    )
  );
});

const isMessagesValid = computed(() => {
  return organizationInfo?.value?.sample1 && organizationInfo?.value?.sample2;
});

const isMessageSubmitted = computed(() => {
  return selectedTdlc?.value?.sample1 && selectedTdlc?.value?.sample2;
});

const validateField = (field) => {
  // if (!organizationInfo.value[field] && field !== "displayName") {
  if (!organizationInfo.value[field]) {
    errors.value[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required`;
  } else {
    errors.value[field] = "";
  }
};

onBeforeMount(() => {
  store.state.app.hideConfigButton = false;
  store.state.app.showNavbar = true;
  store.state.app.showSidenav = true;
  store.state.app.showFooter = true;
  body.classList.add("bg-gray-100");
});

onMounted(async () => {
  store.dispatch("identityData/fetchIdentity", { identityId: identityId.value });
  store.dispatch("identityData/fetchTdlcInfo", { identityId: identityId.value });
  const {
    ein,
    legalEntityType,
    companyName,
    displayName,
    street,
    city,
    state,
    postalCode,
    phone,
    email,
    website,
    vertical,
    sample1,
    sample2,
    campaignVerifyToken,
  } = selectedTdlc.value;
  organizationInfo.value = {
    ein,
    entityType: legalEntityType || "PRIVATE_PROFIT",
    identityName: companyName,
    displayName: displayName || companyName,
    street,
    city,
    state,
    postalCode,
    phone,
    email,
    website,
    isPolitical: selectedIdentity.value.isPolitical || false,
    vertical: vertical  || "GOVERNMENT",
    sample1,
    sample2,
    campaignVerifyToken,
  };
});

const onEntityTypeSelect = (entityType) => {
  organizationInfo.value.entityType = entityType;
  validateField("entityType");
};

const onVerticalSelect = (vertical) => {
  organizationInfo.value.vertical = vertical;
  validateField("vertical");
};

const onRegister = () => {
  if (showModal.value === false) showModal.value = true;
};

const submitProfile = async ({ entity, messageOption, organizationType }) => {
  if (!entity) return;
  const payload = {
    entityType: organizationType?.value || "GOVERNMENT",
    isPolitical: entity.isPolitical,
    vertical: entity.vertical,
    willSendOver2k: messageOption?.id === 0 ? true : false,
  };
  await store.dispatch("identityData/submitIdentity", {
    identityId: identityId.value,
    payload,
  });
  showModal.value = false;
};

const onSubmitOrganizationInfo = async () => {
  // Object.keys(organizationInfo.value).forEach((field) =>
  //   validateField(field, organizationInfo.value[field])
  // );
  organizationInfo.value = {
    ...organizationInfo.value,
    displayName: organizationInfo.value.displayName || organizationInfo.value.identityName,
  }
  if (isFormValid.value) {
    await store.dispatch("identityData/approveBrand", {
      identityId: identityId.value,
      payload: organizationInfo.value,
    });
    console.log("Form submitted successfully", organizationInfo.value);
    organizationInfo.value = {};
    router.push("/identity");
  }
};

const onSubmitMessages = async () => {
  organizationInfo.value = {
    ...organizationInfo.value,
    displayName: organizationInfo.value.displayName || organizationInfo.value.identityName,
  }
  await store.dispatch("identityData/approveBrand", {
    identityId: identityId.value,
    payload: organizationInfo.value,
  });
  organizationInfo.value = {};
  router.push("/identity");
};

const onSubmitCVToken = async () => {
  const payload = {
    sample1: selectedTdlc.value.sample1,
    sample2: selectedTdlc.value.sample2,
    campaignVerifyToken: selectedTdlc.value.campaignVerifyToken,
  };
  console.log(payload);
};
</script>

<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div v-if="!selectedIdentity?.entityType" class="col-12">
        <h2 class="text-white mb-4">{{ selectedIdentity?.identityName }}</h2>
        <div class="card min-vh-50">
          <div class="card-body">
            <div>
              <h5 class="mb-4">Organization Info</h5>
              <p class="mt-4 mb-2">This identity has not registered for 10DLC yet.</p>
              <argon-button
                color="success"
                size="md"
                class="rounded-2xl px-2"
                @click.prevent="onRegister"
              >
                Register Now
              </argon-button>
            </div>
          </div>
        </div>
        <t-d-l-c-profile-registration-modal
          :show="showModal"
          :onSelect="submitProfile"
          @close="showModal = false"
        />
      </div>
      <div v-else>
        <h2 class="text-white mb-4">{{ selectedIdentity?.identityName }}</h2>
        <div class="card">
          <div class="organization-info-header d-flex p-4">
            <argon-button
              v-if="!selectedTdlc?.tcrIdentityStatus"
              color="primary"
              size="md"
              class="border-radius-2xl px-4 ms-auto"
              :disabled="!isFormValid"
              @click="onSubmitOrganizationInfo"
            >
              Submit Organinzation Info
            </argon-button>
            <argon-button
              v-else-if="selectedTdlc?.tcrIdentityStatus && !isMessageSubmitted"
              color="primary"
              size="md"
              class="border-radius-2xl px-4 ms-auto"
              :disabled="!isMessagesValid"
              @click="onSubmitMessages"
            >
              Submit Messages
            </argon-button>
            <argon-button
              v-else-if="!selectedTdlc?.campaignVerifyToken"
              color="primary"
              size="md"
              class="border-radius-2xl px-4 ms-auto"
              @click="onSubmitCVToken"
            >
              Submit CV Token
            </argon-button>
          </div>
          <div class="orginization-info-body p-4">
            <div>
              <div class="section-header mb-4">
                <h5>Organization Info</h5>
              </div>
              <ul class="ps-0">
                <li class="d-flex align-items-center">
                  <i
                    v-if="selectedTdlc?.tcrIdentityStatus === 'VERIFIED'"
                    class="fa fa-check text-success me-2"
                  ></i>
                  <i v-else class="fa fa-times text-warning me-2"></i>
                  <p class="mb-0">
                    This Identity's organization has not been successfully registered with
                    the status of: <b>{{ selectedTdlc?.tcrIdentityStatus }}</b
                    >.
                  </p>
                </li>
                <li class="d-flex align-items-center">
                  <i
                    v-if="!selectedTdlc?.cvVettingStatus === 'ACTIVE'"
                    class="fa fa-times text-warning me-2"
                  ></i>
                  <i v-else class="fa fa-check text-success me-2"></i>
                  <p class="mb-0">
                    This Identity's organization has not been externally vetted by
                    CampaignVerify.Org with the status of:
                    <b>{{ selectedTdlc?.cvVettingStatus }}</b
                    >.
                  </p>
                </li>
                <li class="d-flex align-items-center">
                  <i
                    v-if="!selectedTdlc?.usecase"
                    class="fa fa-times text-warning me-2"
                  ></i>
                  <i v-else class="fa fa-check text-success me-2"></i>
                  <p class="mb-0">
                    This Identity does not have any registered and active use case, and is
                    not ready for 10DLC messages.
                  </p>
                </li>
              </ul>
              <div class="row">
                <div class="col-lg-6 col-12">
                  <label for="organization-ein-input" class="form-control-label">
                    Organization EIN (Employer ID Number):
                  </label>
                  <argon-input
                    type="text"
                    v-model="organizationInfo.ein"
                    @input="validateField('ein')"
                  />
                  <span v-if="errors.ein" class="error">
                    {{ errors.ein }}
                  </span>
                </div>
                <div class="col-lg-6 col-12">
                  <label for="organization-type-input" class="form-control-label">
                    Legal Entity's Organization Type:
                  </label>
                  <!-- <argon-input
                    type="text"
                    v-model="organizationInfo.entityType"
                    @input="validateField('entityType')"
                  /> -->
                  <argon-dropdown class="">
                    <template v-slot:title>
                      <argon-button color="light" class="dropdown-toggle">
                        {{ organizationInfo?.entityType }}
                      </argon-button>
                    </template>
                    <a
                      v-for="(entityType, index) in entityTypes"
                      :key="index"
                      class="dropdown-item d-flex align-items-center"
                      @click="onEntityTypeSelect(entityType)"
                    >
                      <label class="mb-0">{{ entityType }}</label>
                    </a>
                  </argon-dropdown>
                  <span v-if="errors.entityType" class="error">
                    {{ errors.entityType }}
                  </span>
                </div>
                <div class="col-lg-6 col-12">
                  <label for="organization-name-input" class="form-control-label">
                    Organization Legal Name:
                  </label>
                  <argon-input
                    type="text"
                    v-model="organizationInfo.identityName"
                    @input="validateField('identityName')"
                  />
                  <span v-if="errors.identityName" class="error">
                    {{ errors.identityName }}
                  </span>
                </div>
                <div class="col-lg-6 col-12">
                  <label for="organization-display-name-input" class="form-control-label">
                    Display/Operating Name (if different):
                  </label>
                  <argon-input type="text" v-model="organizationInfo.displayName" />
                </div>
                <div class="col-lg-6 col-12">
                  <label for="organization-street-input" class="form-control-label">
                    Street Address:
                  </label>
                  <argon-input
                    type="text"
                    v-model="organizationInfo.street"
                    @input="validateField('street')"
                  />
                  <span v-if="errors.street" class="error">
                    {{ errors.street }}
                  </span>
                </div>
                <div class="col-lg-6 col-12">
                  <label for="organization-city-input" class="form-control-label">
                    City:
                  </label>
                  <argon-input
                    type="text"
                    v-model="organizationInfo.city"
                    @input="validateField('city')"
                  />
                  <span v-if="errors.city" class="error">
                    {{ errors.city }}
                  </span>
                </div>
                <div class="col-lg-6 col-12">
                  <label for="organization-state-input" class="form-control-label">
                    State:
                  </label>
                  <argon-input
                    type="text"
                    v-model="organizationInfo.state"
                    @input="validateField('state')"
                  />
                  <span v-if="errors.state" class="error">
                    {{ errors.state }}
                  </span>
                </div>
                <div class="col-lg-6 col-12">
                  <label for="organization-zip-code-input" class="form-control-label">
                    Zip Code:
                  </label>
                  <argon-input
                    type="text"
                    v-model="organizationInfo.postalCode"
                    @input="validateField('postalCode')"
                  />
                  <span v-if="errors.postalCode" class="error">
                    {{ errors.postalCode }}
                  </span>
                </div>
                <div class="col-lg-6 col-12">
                  <label
                    for="organization-public-contact-phone-input"
                    class="form-control-label"
                  >
                    Public Contact Phone:
                  </label>
                  <argon-input
                    type="text"
                    v-phone-mask
                    v-model="organizationInfo.phone"
                    @input="validateField('phone')"
                  />
                  <span v-if="errors.phone" class="error">
                    {{ errors.phone }}
                  </span>
                </div>
                <div class="col-lg-6 col-12">
                  <label
                    for="organization-public-contact-email-input"
                    class="form-control-label"
                  >
                    Public Contact Email:
                  </label>
                  <argon-input
                    type="text"
                    v-model="organizationInfo.email"
                    @input="validateField('email')"
                  />
                  <span v-if="errors.email" class="error">
                    {{ errors.email }}
                  </span>
                </div>
                <div class="col-lg-6 col-12">
                  <label for="organization-website-input" class="form-control-label">
                    Website:
                  </label>
                  <argon-input
                    type="text"
                    v-model="organizationInfo.website"
                    @input="validateField('website')"
                  />
                  <span v-if="errors.website" class="error">
                    {{ errors.website }}
                  </span>
                </div>
                <div class="col-lg-6 col-12">
                  <label for="organization-vertical-input" class="form-control-label">
                    Vertical:
                  </label>
                  <!-- <argon-input
                    type="text"
                    v-model="organizationInfo.vertical"
                    @input="validateField('vertical')"
                  /> -->
                  <argon-dropdown class="">
                    <template v-slot:title>
                      <argon-button color="light" class="dropdown-toggle">
                        {{ organizationInfo?.vertical }}
                      </argon-button>
                    </template>
                    <a
                      v-for="(vertical, index) in verticals"
                      :key="index"
                      class="dropdown-item d-flex align-items-center"
                      @click="onVerticalSelect(vertical)"
                    >
                      <label class="mb-0">{{ vertical }}</label>
                    </a>
                  </argon-dropdown>
                  <span v-if="errors.vertical" class="error">
                    {{ errors.vertical }}
                  </span>
                </div>
              </div>
              <div class="col-12 border-radius-xl bg-danger-soft my-4 p-4">
                This form will be sent to The Campaign Registry which handles all 10DLC
                registrations. To start, you'll need an Employer Identification Number or
                EIN that was issued by the Internal Revenue Service. If your organization
                does not have an EIN, you can apply online here. Next, we'll need the
                address that was used to register your campaign. Provide an official
                public email address as well as a public phone number for your
                organization for you registration. After you click “Submit Organization
                Info,” the 10DLC application process will have been started for you. This
                form will be sent to Campaign Verify for verification.
              </div>
            </div>
            <div>
              <div class="section-header mb-4">
                <h5>Sample Messages</h5>
              </div>
              <div class="mb-4">
                <label for="sample-message-1-input" class="form-control-label">
                  Sample Message 1:
                </label>
                <argon-input
                  id="sample1"
                  type="text"
                  name="sample1"
                  size="md"
                  v-model="organizationInfo.sample1"
                />
              </div>
              <div class="mb-4">
                <label for="sample-message-2-input" class="form-control-label">
                  Sample Message 2:
                </label>
                <argon-input
                  id="sample2"
                  type="text"
                  name="sample2"
                  size="md"
                  v-model="organizationInfo.sample2"
                />
              </div>
              <div class="border-radius-xl bg-danger-soft my-4 p-4">
                Please provide two different sample messages that would be good examples
                of the messages you will send. Remember, the more conversational, the
                better. You might not yet know exactly what your messages will be, but
                please try to be as close as possible. If you are going to ask for
                donations, at least one of your message samples must mention donating.
                Remember to include opt-out language such as "Reply STOP to opt-out," as
                well as "{first_name}" as a placeholder for your contacts' names.
              </div>
            </div>
            <div>
              <div class="section-header mb-4">
                <h5>Campaign Verify Vetting</h5>
              </div>
              <div class="d-flex mb-4">
                <strong
                  >Campaign Verify Token Status:
                  <span class="text-success">{{ selectedTdlc?.cvVettingStatus }}</span>
                </strong>
              </div>
              <div>
                <label for="cv-token-input" class="form-control-label">
                  Please copy and paste your Campaign Verify Token here:
                </label>
                <argon-input
                  id="cvToken"
                  type="text"
                  name="cvToken"
                  size="md"
                  v-model="organizationInfo.campaignVerifyToken"
                />
                <span v-if="errors.campaignVerifyToken" class="error">
                  {{ errors.campaignVerifyToken }}
                </span>
              </div>
              <div class="border-radius-xl bg-danger-soft my-4 p-4">
                Campaign Verify Tokens are part of the 10DLC process to verify the
                identity of your political campaign to the Wireless Carriers. Your token
                is required to complete your 10DLC registration. Any candidate, party,
                PAC, or other committee that is a 527 tax-exempt organization and
                registered with the Federal Elections Commission (FEC) or a State, County,
                Tribal, or Local Election Authority is eligible to obtain verification
                through Campaign Verify. To get a token, please visit
                <a href="https://dev.soapboxbulletin.com/campaign-verify/" target="blank">
                  dev.soapboxbulletin.com/campaign-verify/
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.error {
  color: red;
  font-size: 0.9em;
}
</style>
