// src/directives/v-phone-mask.js
const formatPhone = (value) => {
  value = value.replace(/\D/g, ''); // Remove all non-numeric characters
  if (value.length <= 3) {
    return value;
  } else if (value.length <= 6) {
    return `(${value.slice(0, 3)}) ${value.slice(3)}`;
  } else {
    return `(${value.slice(0, 3)}) ${value.slice(3, 6)}-${value.slice(6, 10)}`;
  }
};

const getRawValue = (formattedValue) => {
  return formattedValue.replace(/[()\s-]/g, '');
};

const maskInput = (event) => {
  const input = event.target;
  const rawValue = getRawValue(input.value);
  const formattedValue = formatPhone(rawValue);

  const previousValue = input.value;
  const previousCursorPosition = input.selectionStart;

  input.value = formattedValue;

  let newCursorPosition = previousCursorPosition + (formattedValue.length - previousValue.length);

  if (formattedValue.charAt(newCursorPosition - 1) === ' ') {
    newCursorPosition++;
  } else if (formattedValue.charAt(newCursorPosition - 1) === ')') {
    newCursorPosition += 2;
  }

  setTimeout(() => {
    input.setSelectionRange(newCursorPosition, newCursorPosition);
  }, 0);
};

export default {
  mounted(el) {
    el.addEventListener('input', maskInput);
  },
  unmounted(el) {
    el.removeEventListener('input', maskInput);
  }
};
