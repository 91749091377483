<template>
  <div class="tdlc-profile-registration-modal" v-if="props.show">
    <div class="modal-overlay" @click.prevent="close"></div>
    <div class="modal-content">
      <div class="d-flex align-items-center modal-content-header p-4">
        <h5 class="mb-0">10DLC Profile Registration</h5>
        <span class="close ms-auto" @click.prevent="close">&times;</span>
      </div>
      <div class="modal-content-body p-4">
        <h6>Is this identity a Political Campaign, PAC, Committee, or other Political Entity?:</h6>
        <div v-for="(entityType, index) in entityTypes" :key="index">
          <input
            type="radio"
            :id="entityType.id"
            :value="entityType"
            v-model="selectedEntity"
          />
          <label :for="entityType.id">{{ entityType.text }}</label>
        </div>
        <div v-if="selectedEntity?.id === 2" class="mt-4">
          <label>What is the legal entity's organization type?:</label>
          <argon-dropdown class="">
            <template v-slot:title>
              <argon-button color="light" class="dropdown-toggle">
                {{ selectedOrganizationType?.text || "Select Organization Legal Type" }}
              </argon-button>
            </template>
            <a
              v-for="(organizationType, index) in organizationTypes"
              :key="index"
              class="dropdown-item d-flex align-items-center"
              @click="selectedOrganizationType = organizationType"
            >
              <label class="mb-0">{{ organizationType.text }}</label>
            </a>
          </argon-dropdown>
        </div>
        <div v-if="selectedEntity?.id === 1 || selectedEntity?.id === 2" class="mt-4">
          <h6>Might this identity need to send more than 2,000 messages per day?:</h6>
          <div v-for="(option, index) in messageOptions" :key="index">
            <input
              type="radio"
              :id="option.id"
              :value="option"
              v-model="selectedMessageOption"
            />
            <label :for="option.id">{{ option.text }}</label>
          </div>
        </div>
      </div>
      <div class="d-flex modal-content-footer p-4">
        <argon-button
          color="primary"
          class="border-radius-2xl mb-0 ms-auto"
          @click.prevent="onContinue"
        >
          Continue
        </argon-button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits } from "vue";
import ArgonButton from "@/components/Core/ArgonButton.vue";
import ArgonDropdown from "@/components/Core/ArgonDropdown.vue";

const props = defineProps({
  show: {
    type: Boolean,
    required: true,
  },
  onSelect: {
    type: Function,
    required: true,
  },
});

const entityTypes = ref([
  {
    id: 0,
    isPolitical: true,
    vertical: "POLITICAL",
    text: "Yes, this is a Political Campaign, Candidate, PAC, Super-PAC, Committee, Party, 527-registered organization, or other Political Entity.",
  },
  {
    id: 1,
    isPolitical: false,
    vertical: null,
    text: "No, this is a politically-active Charity (501c3), Social Welfare Organization (501c4), Trade Union (501c5), or a Business League / Chamber of Commerce (501c6).",
  },
  {
    id: 2,
    isPolitical: false,
    vertical: null,
    text: "No, this is an entirely non-political organization that does not engage in any campaigning or advocacy.",
  },
]);
const organizationTypes = ref([
  {
    id: 0,
    text: "Non-Profit Organization",
    value: "NON_PROFIT"
  },
  {
    id: 1,
    text: "Private Company",
    value: "PRIVATE_PROFIT",
  },
  {
    id: 2,
    text: "Government",
    value: "GOVERNMENT_PROFIT",
  },
]);
const messageOptions = ref([
  {
    id: 0,
    text: "Yes, this identity may need to send more than 2,000 messages per day."
  },
  {
    id: 1,
    text: "No, this identity will not need to send more than 2,000 messages per day."
  },
]);
const selectedEntity = ref(null);
const selectedOrganizationType = ref(null);
const selectedMessageOption = ref(0);

const emit = defineEmits(["close"]);

const close = () => {
  emit("close");
};

const onContinue = () => {
  props.onSelect({
    entity: selectedEntity.value,
    messageOption: selectedMessageOption.value,
    organizationType: selectedOrganizationType.value,
  });
};
</script>

<style scoped>
/* Modal styles */
.tdlc-profile-registration-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

/* Modal content */
.modal-content {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 0;
  border: 1px solid #888;
  width: 75%; /* Could be more or less, depending on screen size */
  min-height: 50vh;
}

.modal-content-header {
  border-bottom: 1px solid gray;
}

/* Profile registration container */
.modal-content-body {
  position: relative;
  width: 100%;
  height: 100%;
  flex: 1;
}

.modal-content-footer {
  border-top: 1px solid gray;
}

/* Close button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
</style>