<script setup>
import { ref, watch } from "vue";
import { useStore } from "vuex";

import ArgonButton from "@/components/Core/ArgonButton.vue";
import SearchBar from "@/components/PageLayout/SearchBar.vue";

const store = useStore();

const props = defineProps({
  data: Object,
  emitValidState: Function,
  addSuppressionList: Function,
});

const { emitValidState, addSuppressionList } = props;

const formData = ref({ ...props.data });

// Watch for changes in suppressionListData and update formData accordingly
watch(
  () => props.data,
  (newValue) => {
    formData.value = { ...newValue };
  },
  { deep: true, immediate: true }
);

// Watch for changes in formData
watch(
  formData,
  () => {
    emitValidState(true);
  },
  { deep: true }
);

const onAdd = () => {
  addSuppressionList();
};

const onAssign = (suppressionList) => {
  formData.value = {
    available: formData.value.available?.filter((item) => item.id !== suppressionList.id),
    assigned: [...formData.value.assigned, suppressionList],
  };
  store.commit("jobData/updateSuppressionListStepData", formData.value);
};

const onCreateResultsPhoneList = () => {
  //
};

const onNoCreateResultsPhoneList = () => {
  //
};

const onUnassign = (suppressionList) => {
  formData.value = {
    available: [...formData.value.available, suppressionList],
    assigned: formData.value.assigned?.filter((item) => item.id !== suppressionList.id),
  };
  store.commit("jobData/updateSuppressionListStepData", formData.value);
};
</script>

<template>
  <div class="row">
    <div class="col-12">
      <h2 class="text-dark mb-4">Suppression List</h2>
    </div>
    <div class="col-lg-6 col-12">
      <div class="d-flex align-items-center">
        <h4 class="text-dark mb-3 me-4">Available Suppression Lists</h4>
        <search-bar />
      </div>
      <div class="d-flex align-items-center">
        <argon-button class="rounded-circle ms-auto px-3" @click="onAdd">
          +
        </argon-button>
      </div>
      <div class="table-responsive p-0 mt-4">
        <table class="table align-items-center justify-content-center mb-0">
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Name
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                State
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Leads Loaded
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              ></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in formData.available" :key="index">
              <td class="px-4">{{ item.listName }}</td>
              <td class="px-4">{{ item.listState }}</td>
              <td class="px-4">{{ item.leadsLoaded || 0 }}</td>
              <td class="px-4">
                <div class="d-flex">
                  <argon-button
                    color="primary"
                    size="sm"
                    class="action-btn rounded-circle ms-auto px-2"
                    @click="onAssign(item)"
                  >
                    <i class="fas fa-user-plus text-xs text-white" aria-hidden="true"></i>
                  </argon-button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="col-lg-6 col-12">
      <div class="d-flex align-items-center">
        <h4 class="text-dark mb-3 me-4">Assigned Suppression Lists</h4>
      </div>
      <div class="table-responsive p-0 mt-4">
        <table class="table align-items-center justify-content-center mb-0">
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Name
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                State
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Leads Loaded
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              ></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in formData.assigned" :key="index">
              <td class="px-4">{{ item.listName }}</td>
              <td class="px-4">{{ item.listState }}</td>
              <td class="px-4">{{ item.leadsLoaded || 0 }}</td>
              <td class="px-4">
                <div class="d-flex">
                  <argon-button
                    v-if="item.createResultsPhoneList"
                    color="success"
                    size="sm"
                    class="action-btn rounded-cricle ms-auto me-2 px-2"
                    @click="onCreateResultsPhoneList"
                  >
                    <i class="fas fa-list text-xs text-white" aria-hidden="true"></i>
                  </argon-button>
                  <argon-button
                    v-if="!item.createResultsPhoneList"
                    color="warning"
                    size="sm"
                    class="action-btn rounded-circle ms-auto me-2 px-2"
                    @click="onNoCreateResultsPhoneList"
                  >
                    <i class="fas fa-list text-xs text-white" aria-hidden="true"></i>
                  </argon-button>
                  <argon-button
                    color="dark"
                    size="sm"
                    class="action-btn rounded-circle ms-auto me-2 px-2"
                    @click="onUnassign(item)"
                  >
                    <i class="fas fa-minus text-xs text-white" aria-hidden="true"></i>
                  </argon-button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<style>
.action-btn {
  width: 34px;
  height: 34px;
}
</style>
