<script setup>
import { onBeforeMount, onMounted, ref, computed } from "vue";
import { useStore } from "vuex";
import axios from "axios";
// import ArgonButton from "@/components/Core/ArgonButton.vue";
// import GradientLineChart from "@/components/GradientLineChart.vue";
import {
  // getRoundedPercentage,
  // getPercentages,
  // sum,
  removeHtmlTags,
  extractLinks,
  getFormattedDate,
} from "@/utils";

const body = document.getElementsByTagName("body")[0];
const store = useStore();
const job = computed(() => store.getters["jobData/getSelectedJob"]);
const selectedIdentity = computed(() => store.getters["identityData/getSelectedIdentity"]);
const isLoading = computed(() => store.getters["messageData/isLoading"]);
// const outgoingMessages = computed(() => store.getters["messageData/getOutgoingMessages"]);
// const incomingMessages = computed(() => store.getters["messageData/getIncomingMessages"]);
// const failedMessages = computed(() => store.getters["messageData/getFailedMessages"]);
// const outgoingSuccessfulMessages = outgoingMessages.value?.map(item => +item.messageCount);
// const incomingSuccessfulMessages = incomingMessages.value?.map(item => +item.messageCount);
// const outgoingFailedMessages = computed(() => failedMessages.value?.map(item => +item.messageCount));
// const incomingFailedMessages = computed(() => failedMessages.value?.map(item => +item.messageCount));
const clicks = computed(() => store.getters["messageData/getClicks"]);
// const outgoingSuccessfulMessages = computed(() =>
//   outgoingMessages.value.map((m) => m.outgoingMessages)
// );
// const outgoingFailedMessages = computed(() =>
//   outgoingMessages.value.map((m) => m.failed)
// );
// const incomingSuccessfulMessages = computed(() =>
//   incomingMessages.value.map((m) => m.incomingMessages)
// );
// const incomingFailedMessages = computed(() =>
//   incomingMessages.value.map((m) => m.failed)
// );

const loading = ref(false);

// const chartLabels = [
//   "Jan",
//   "Feb",
//   "Mar",
//   "Apr",
//   "May",
//   "Jun",
//   "Jul",
//   "Aug",
//   "Sep",
//   "Oct",
//   "Nov",
//   "Dec",
// ];

onBeforeMount(() => {
  store.state.app.hideConfigButton = false;
  store.state.app.showNavbar = true;
  store.state.app.showSidenav = true;
  store.state.app.showFooter = true;
  body.classList.add("bg-gray-100");
});

onMounted(async () => {
  const trackLinks = extractLinks(removeHtmlTags(job.value.initialTemplateMessage));
  await store.dispatch("identityData/fetchIdentity", { identityId: job.value.identityId });
  await store.dispatch("messageData/fetchMessages", { jobId: job.value.jobId });
  if (trackLinks.length > 0) {
    await store.dispatch("messageData/fetchLinkClicks", { link: trackLinks[0] });
  }
});

const getMediaType = async (url) => {
  try {
    const response = await axios.head(url);
    const contentType = response.headers['content-type'];
    if (!contentType) {
      return 'UNKNOWN';
    } else if (contentType.startsWith('image/')) {
      return 'IMAGE';
    } else if (contentType.startsWith('video/')) {
      return 'VIDEO';
    } else {
      return 'UNKNOWN';
    }
  } catch (error) {
    console.error(`Error fetching the URL: ${error.message}`);
    return null;
  }
};

const onPrint = async () => {
  loading.value = true;
  const histories = await axios.get(`${process.env.VUE_APP_API_URL}/messages/history/${job.value.jobId}`);
  if (!histories.data) return;
  downloadCSV(histories.data);
  loading.value = false;
};

/* Consider later
const downloadJSON = (downloadData) => {
  // Convert download data to JSON string
  const jsonString = JSON.stringify(downloadData, null, 2); // Pretty print with indentation
  // Create a Blob from the JSON string
  const blob = new Blob([jsonString], { type: "application/json" });
  // Create a link element
  const link = document.createElement("a");
  // Set the URL using the Blob
  link.href = URL.createObjectURL(blob);
  // Set the file name
  link.download = "data.json";
  // Append the link to the body (required for Firefox)
  document.body.appendChild(link);
  // Programmatically click the link to trigger download
  link.click();
  // Remove the link from the body
  document.body.removeChild(link);
}
*/


const downloadCSV = (downloadData) => {
  if (!downloadData || !downloadData[0]) return;

  // Extract the headers
  const headers = Object.keys(downloadData[0]);

  // Create an array of CSV rows
  const csvRows = [];

  // Add the header row
  csvRows.push(headers.join(','));

  // Add the data rows
  for (const row of downloadData) {
    const values = headers.map(header => {
      const val = row[header] === null || row[header] === undefined ? '' : row[header];
      return `"${val}"`; // Enclose each value in quotes to handle commas within fields
    });
    csvRows.push(values.join(','));
  }

  // Combine all rows into a single CSV string
  const csvString = csvRows.join('\n');

  // Create a Blob from the CSV string
  const blob = new Blob([csvString], { type: "text/csv" });
  
  // Create a link element
  const link = document.createElement("a");
  
  // Set the URL using the Blob
  link.href = URL.createObjectURL(blob);
  
  // Set the file name
  link.download = "data.csv";
  
  // Append the link to the body (required for Firefox)
  document.body.appendChild(link);
  
  // Programmatically click the link to trigger download
  link.click();
  
  // Remove the link from the body
  document.body.removeChild(link);
};

</script>

<template>
  <div class="py-4 container-fluid">
    <div v-if="isLoading" class="text-white">Loading...</div>
    <div v-else class="row">
      <div class="col-lg-12">
        <h2 class="text-white mb-4">Reports</h2>
        <div class="row">
          <div class="col-lg-6 col-12">
            <div class="d-flex align-items-center mb-2">
              <label for="account-name" class="text-white mb-0">Account Name:</label>
              <p class="text-white mb-0 ms-2">{{ selectedIdentity?.displayName || selectedIdentity?.identityName }}</p>
            </div>
            <div class="d-flex align-items-center mb-2">
              <label for="campaign-title" class="text-white mb-0">Campaign Title:</label>
              <p class="text-white mb-0 ms-2">{{ job.name }}</p>
            </div>
            <div class="d-flex align-items-center mb-2">
              <label for="send-date" class="text-white mb-0">Send Date:</label>
              <p class="text-white mb-0 ms-2">{{ getFormattedDate(job.startDate) }}</p>
            </div>
            <div v-if="job.initialTemplateMedia">
              <div v-if="getMediaType(job.initialTemplateMedia) === 'IMAGE'" class="media-container">
                <img :src="formData.initialTemplateMedia" alt="Image" class="media">
              </div>
              <div v-else-if="getMediaType(job.initialTemplateMedia) === 'VIDEO'" class="media-container">
                <video controls class="media">
                  <source :src="job.initialTemplateMedia" type="video/mp4">
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-end mb-2">
            <argon-button
              color="light"
              :disabled="loading"
              @click="onPrint"
            >
              <i class="fa fa-print text-xl text-warning"></i>
            </argon-button>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6 col-12">
            <div class="card min-vh-50 mt-4">
              <div class="card-body">
                <div v-html="job.initialTemplateMessage" class="message-content"></div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-12">
            <div class="card min-vh-50 mt-4">
              <div class="card-body">
                <div class="row">
                  <div class="col-lg-6 col-12">
                    <div class="d-flex align-items-center mb-2">
                      <label for="messages-sent" class="text-black mb-0">Messages Sent:</label>
                      <p class="text-black mb-0 ms-2">{{ job.messagesSent }}</p>
                    </div>
                    <div class="d-flex align-items-center mb-2">
                      <label for="replies-received" class="text-black mb-0">Non-Opt Out Replies Received:</label>
                      <p class="text-black mb-0 ms-2">{{ job.messagesNonOptOut }}</p>
                    </div>
                    <div class="d-flex align-items-center mb-2">
                      <label for="replies-received" class="text-black mb-0">Opt Out Replies Received:</label>
                      <p class="text-black mb-0 ms-2">{{ job.messagesOptOut }}</p>
                    </div>
                    <div class="d-flex align-items-center mb-2">
                      <label for="clicks" class="text-black mb-0">Clicks:</label>
                      <p class="text-black mb-0 ms-2">{{ clicks }}</p>
                    </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="card min-vh-50 mt-4">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-6 col-12">
                <div v-html="job.initialTemplateMessage" class="message-content"></div>
              </div>
            </div>
            <div v-if="false" class="d-flex justify-content-end mb-2">
              <argon-button
                color="light"
                :disabled="loading"
                @click="onPrint"
              >
                <i class="fa fa-print text-xl text-warning"></i>
              </argon-button>
            </div>
            <div v-if="false" class="row mb-4">
              <div class="col-lg-6 col-12">
                <div class="card z-index-2">
                  <gradient-line-chart
                    v-if="outgoingSuccessfulMessages && outgoingFailedMessages"
                    id="outgoing-messages-chart"
                    title="Outgoing Messages"
                    description="<i class='fa fa-arrow-right text-success'></i>"
                    :chart="{
                      labels: chartLabels,
                      datasets: [
                        {
                          label: 'Sent',
                          data: outgoingSuccessfulMessages,
                        },
                        {
                          label: 'Failed',
                          data: outgoingFailedMessages,
                        },
                      ],
                    }"
                  />
                  <div class="row my-4 mx-2">
                    <div class="col-lg-6 col-12">
                      <div class="card z-index-3 p-2">
                        <div class="d-flex justify-content-between align-items-center mb-4">
                          <p class="mb-0">Sent</p>
                          <i class="fa fa-solid fa-circle text-success"></i>
                        </div>
                        <div>
                          <p class="text-3xl mb-0">
                            {{
                              `${
                                getRoundedPercentage(getPercentages(
                                  sum(outgoingSuccessfulMessages),
                                  sum(outgoingFailedMessages)
                                )[0])
                              }%`
                            }}
                          </p>
                          <p class="text-sm text-success mb-0">
                            {{ `${sum(outgoingSuccessfulMessages)} Messages` }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6 col-12">
                      <div class="card z-index-3 p-2">
                        <div class="d-flex justify-content-between align-items-center mb-4">
                          <p class="mb-0">Messages with Errors</p>
                          <i class="fa fa-solid fa-circle text-danger"></i>
                        </div>
                        <div>
                          <p class="text-3xl mb-0">
                            {{
                              `${
                                getRoundedPercentage(getPercentages(
                                  sum(outgoingSuccessfulMessages),
                                  sum(outgoingFailedMessages)
                                )[1])
                              }%`
                            }}
                          </p>
                          <p class="text-sm text-success mb-0">
                            {{ `${sum(outgoingFailedMessages)} Messages` }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-12">
                <div class="card z-index-2">
                  <gradient-line-chart
                    id="incoming-messages-chart"
                    title="Incoming Messages"
                    description="<i class='fa fa-arrow-left text-success'></i>"
                    :chart="{
                      labels: chartLabels,
                      datasets: [
                        {
                          label: 'Received',
                          data: incomingSuccessfulMessages,
                        },
                        {
                          label: 'Failed',
                          data: incomingFailedMessages,
                        },
                      ],
                    }"
                  />
                  <div class="row my-4 mx-2">
                    <div class="col-lg-6 col-12">
                      <div class="card z-index-3 p-2">
                        <div class="d-flex justify-content-between align-items-center mb-4">
                          <p class="mb-0">Received</p>
                          <i class="fa fa-solid fa-circle text-success"></i>
                        </div>
                        <div>
                          <p class="text-3xl mb-0">
                            {{
                              `${
                                getRoundedPercentage(getPercentages(
                                  sum(incomingSuccessfulMessages),
                                  sum(incomingFailedMessages)
                                )[0])
                              }%`
                            }}
                          </p>
                          <p class="text-sm text-success mb-0">
                            {{ `${sum(incomingSuccessfulMessages)} Messages` }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6 col-12">
                      <div class="card z-index-3 p-2">
                        <div class="d-flex justify-content-between align-items-center mb-4">
                          <p class="mb-0">Opt-out Received</p>
                          <i class="fa fa-solid fa-circle text-danger"></i>
                        </div>
                        <div>
                          <p class="text-3xl mb-0">
                            {{
                              `${
                                getRoundedPercentage(getPercentages(
                                  sum(incomingSuccessfulMessages),
                                  sum(incomingFailedMessages)
                                )[1])
                              }%`
                            }}
                          </p>
                          <p class="text-sm text-success mb-0">
                            {{ `${sum(incomingFailedMessages)} Messages` }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<style>
.message-content {
  p {
    margin-bottom: 0 !important;
  }
}
</style>
