// store/modules/identityData.js
import axios from "axios";

const state = {
  selectedIdentity: null,
  identities: [],
  selectedTdlc: {},
  loading: false,
  error: null,
  showAddIdentityPanel: false,
};

const mutations = {
  setSelectedIdentity(state, identity) {
    state.selectedIdentity = identity;
  },
  setIdentities(state, identities) {
    state.identities = identities;
  },
  setSelectedTdlc(state, tdlc) {
    state.selectedTdlc = tdlc;
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
  setError(state, error) {
    state.error = error;
  },
  toggleAddIdentityPanel(state) {
    state.showAddIdentityPanel = !state.showAddIdentityPanel;
  } 
};

const actions = {
  async fetchIdentities({ commit }, { userId }) {
    commit("setLoading", true);
    try {
      const response = await axios.get(`${process.env.VUE_APP_API_URL}/identities?account_id=${userId}`);
      commit("setIdentities", response.data);
      commit("setError", null);
    } catch (error) {
      console.error("Error fetching identities", error);
      commit("setError", error.message);
    } finally {
      commit("setLoading", false);
    }
  },
  async fetchIdentity({ commit }, { identityId }) {
    commit("setLoading", true);
    try {
      const response = await axios.get(`${process.env.VUE_APP_API_URL}/identities/${identityId}`);
      commit("setSelectedIdentity", response.data);
      commit("setError", null);
    } catch (error) {
      console.error("Error fetching identity", error);
      commit("setError", error.message);
    } finally {
      commit("setLoading", false);
    }
  },
  async searchIdentities({ commit }, { userId, query }) {
    commit("setLoading", true);
    try {
      const response = await axios.get(`${process.env.VUE_APP_API_URL}/identities?account_id=${userId}&query=${query}`);
      commit("setIdentities", response.data);
      commit("setError", null);
    } catch (error) {
      console.error("Error searching identities:", error);
      commit("setError", error.message);
    } finally {
      commit("setLoading", false);
    }
  },
  async addIdentity({ commit }, payload) {
    commit("setLoading", true);
    try {
      const response = await axios.post(`${process.env.VUE_APP_API_URL}/identities`, payload);
      commit("setSelectedIdentity", response.data);
      commit("setError", null);
    } catch (error) {
      console.error("Error adding identity", error);
      commit("setError", error.message);
    } finally {
      commit("setLoading", false);
    }
  },
  async submitIdentity({ commit }, { identityId, payload }) {
    commit("setLoading", true);
    try {
      const response = await axios.put(`${process.env.VUE_APP_API_URL}/identities/${identityId}`, payload);
      commit("setSelectedIdentity", response.data.updatedIdentity);
      commit("setError", null);
    } catch (error) {
      console.error("Error submitting identity", error);
      commit("setError", error.message);
    } finally {
      commit("setLoading", false);
    }
  },
  async fetchTdlcInfo({ commit }, { identityId }) {
    commit("setLoading", true);
    try {
      const response = await axios.get(`${process.env.VUE_APP_API_URL}/identities/${identityId}/getTdlcInfo`);
      commit("setSelectedTdlc", response.data);
      commit("setError", null);
    } catch (error) {
      console.error("Error fetching tdlc info", error);
      commit("setError", error.message);
      commit("setSelectedTdlc", {});
    } finally {
      commit("setLoading", false);
    }
  },
  async approveBrand({ commit }, { identityId, payload }) {
    commit("setLoading", true);
    try {
      await axios.post(`${process.env.VUE_APP_API_URL}/identities/${identityId}/approveBrand`, payload);
      commit("setError", null);
    } catch (error) {
      console.error("Error approving brand", error);
      commit("setError", error.message);
    } finally {
      commit("setLoading", false);
    }
  },
};

const getters = {
  getSelectedIdentity: state => state.selectedIdentity,
  getSelectedTdlc: state => state.selectedTdlc,
  getIdentities: state => state.identities,
  isLoading: state => state.loading,
  getError: state => state.error,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
