// store/modules/templateData.js
import axios from "axios";

const state = {
  templates: [],
  loading: false,
  error: null,
};

const mutations = {
  setTemplates(state, templates) {
    state.templates = templates;
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
  setError(state, error) {
    state.error = error;
  },
};

const actions = {
  async fetchTemplates({ commit }) {
    commit("setLoading", true);
    try {
      const response = await axios.get(`${process.env.VUE_APP_API_URL}/templates`);
      commit("setTemplates", response.data);
      commit("setError", null);
    } catch (error) {
      console.error("Error fetching templates:", error);
      commit("setError", error.message);
    } finally {
      commit("setLoading", false);
    }
  },
  async addTemplate({ commit }, payload) {
    commit("setLoading", true);
    try {
      await axios.post(`${process.env.VUE_APP_API_URL}/templates`, payload);
      commit("setError", null);
    } catch (error) {
      console.error("Error adding template:", error);
      commit("setError", error.message);
    } finally {
      commit("setLoading", false);
    }
  },
  async updateTemplate({ commit }, payload) {
    commit("setLoading", true);
    try {
      await axios.put(`${process.env.VUE_APP_API_URL}/templates/${payload.id}`, payload);
      commit("setError", null);
    } catch (error) {
      console.error("Error updating template:", error);
      commit("setError", error.message);
    } finally {
      commit("setLoading", false);
    }
  },
  async deleteTemplate({ commit }, templateId) {
    commit("setLoading", true);
    try {
      await axios.delete(`${process.env.VUE_APP_API_URL}/templates/${templateId}`);
      commit("setError", null);
    } catch (error) {
      console.error("Error deleting template", error);
      commit("setError", error.message);
    } finally {
      commit("setLoading", false);
    }
  },
};

const getters = {
  getTemplates: state => state.templates,
  isLoading: state => state.loading,
  getError: state => state.error,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
