<template>
  <div class="preview-media-modal" v-if="show">
    <div class="modal-overlay" @click="close"></div>
    <div class="modal-content">
      <div class="d-flex">
        <span class="close ms-auto" @click="close">&times;</span>
      </div>
      <div v-if="mediaType === 'IMAGE'" class="media-container">
        <img :src="mediaSrc" alt="Image" class="media">
      </div>
      <div v-else-if="mediaType === 'VIDEO'" class="media-container">
        <video controls class="media">
          <source :src="mediaSrc" type="video/mp4">
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from "vue";

defineProps({
  show: {
    type: Boolean,
    required: true,
  },
  mediaType: {
    type: String,
    required: true,
  },
  mediaSrc: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(["close"]);

const close = () => {
  emit("close");
};
</script>

<style scoped>
/* Modal styles */
.preview-media-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

/* Modal content */
.modal-content {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
}

/* Media container */
.media-container {
  position: relative;
  width: 100%;
  height: 100%;
}

/* Media */
.media {
  width: 100%;
  height: 100%;
  max-height: 80vh;
  object-fit: contain; /* Maintain aspect ratio and fit content */
}

/* Close button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
</style>
