<script setup>
import { ref, computed, onBeforeMount, onMounted } from "vue";
import { useStore } from "vuex";
import ArgonButton from "@/components/Core/ArgonButton.vue";
import SearchBar from "@/components/PageLayout/SearchBar.vue";

const body = document.getElementsByTagName("body")[0];

const store = useStore();
const agents = computed(() => store.getters["agentData/getAgents"]);
const loading = computed(() => store.getters["agentData/isLoading"]);
const error = computed(() => store.getters["agentData/getError"]);
const userId = computed(() => store.getters["user/currentUser"].id);

const agentSearch = ref("");

onBeforeMount(() => {
  store.state.app.hideConfigButton = false;
  store.state.app.showNavbar = true;
  store.state.app.showSidenav = true;
  store.state.app.showFooter = true;
  body.classList.add("bg-gray-100");
});

onMounted(() => {
  store.dispatch("agentData/fetchAgents", { userId: userId.value });
});

const handleAgentSearch = () => {
  store.dispatch("agentData/searchAgents", {
    userId: userId.value,
    query: agentSearch.value
  ,});
};

const exportReport = () => {
  // account_id, agent_id, agent_name, first_text(time), last_text(time), messages_sent(number)
};

const onAdd = () => {
  store.commit("jobData/toggleAddAgentPanel");
};
</script>

<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12">
        <h2 class="text-white mb-4">P2P SMS Agents</h2>
        <div class="card min-vh-50">
          <div class="card-body">
            <div class="d-flex align-items-center mb-4">
              <search-bar v-model="agentSearch" @input="handleAgentSearch" />
              <argon-button color="primary" class="border-radius-2xl ms-2" @click="exportReport">
                Export Report
              </argon-button>
              <argon-button
                color="success"
                size="sm"
                class="action-btn rounded-circle ms-auto px-2"
                @click="onAdd()"
              >
                <i class="fas fa-plus text-xs text-white" aria-hidden="true"></i>
              </argon-button>
            </div>
            <div v-if="loading">Loading...</div>
            <div v-else-if="error">{{ error }}</div>
            <div v-else class="table-responsive p-0">
              <table class="table align-items-center justify-content-center mb-0">
                <thead>
                  <tr>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Email
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Display Name
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      First Name
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Last Name
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Status
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      ID
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(agent, index) in agents" :key="index">
                    <td class="px-4">{{ agent.displayEmail }}</td>
                    <td class="px-4">{{ agent.displayName }}</td>
                    <td class="px-4">{{ agent.firstName }}</td>
                    <td class="px-4">{{ agent.lastName }}</td>
                    <td class="px-4">{{ agent.status }}</td>
                    <td class="px-4">{{ agent.id }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
