import axios from "axios";

// Function to convert 24-hour formatted time to decimal time
export const getDecimalTime = (formattedTime) => {
  // Split the formatted time into hours and minutes
  const [hours, minutes] = formattedTime.split(':').map(Number);

  // Calculate the decimal value
  const decimalTime = hours + minutes / 60;

  return decimalTime;
};

// Function to get 24-hour time format
export const getFormatted24hTime = (decimalTime) => {
  // Split the decimal time into hours and minutes
  const hours = Math.floor(decimalTime);
  const minutes = Math.round((decimalTime - hours) * 60);

  // Format the time in 24-hour format
  const time24 = (hours < 10 ? "0" : "") + hours + ":" + (minutes < 10 ? "0" : "") + minutes;

  return time24;
};

// FUnction to get formatted date
export const getFormattedDate = (dateStr) => {
  const date = new Date(dateStr);
  
  // Get date in YYYY-MM-DD format
  const formattedDate = date.toISOString().split('T')[0];

  return formattedDate;
};

// Function to get AM/PM time format
export const getFormattedTime = (decimalTime) => {
  // Split the decimal time into hours and minutes
  let hours = Math.floor(decimalTime);
  const minutes = Math.round((decimalTime - hours) * 60);

  // Determine AM or PM
  const period = hours >= 12 ? "PM" : "AM";

  // Convert hours to 12-hour format
  hours = hours % 12 || 12;

  // Format the time in 12-hour format
  const time12 = hours + ":" + (minutes < 10 ? "0" : "") + minutes + " " + period;

  return time12;
};

// Debounce function
export const debounce = (func, wait) => {
  let timeout;
  return function(...args) {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), wait);
  };
};

// Function to convert any phone number to E.164 format
export const toE164 = (phoneNumber, countryCode) => {
  // Remove all non-numeric characters except the plus sign
  phoneNumber = phoneNumber.replace(/[^\d+]/g, '');

  // Check if the phone number already starts with a plus sign
  if (phoneNumber.startsWith('+')) {
      return phoneNumber;
  }

  // Remove leading zeros from the phone number
  phoneNumber = phoneNumber.replace(/^0+/, '');

  // Add the plus sign and country code
  return `+${countryCode}${phoneNumber}`;
}

// Function to get states of US
export const getStates = () => {
  const states = [
    { name: "Alabama", code: "AL", areaCodes: ["205", "251", "256", "334", "938"] },
    { name: "Alaska", code: "AK", areaCodes: ["907"] },
    { name: "Arizona", code: "AZ", areaCodes: ["480", "520", "602", "623", "928"] },
    { name: "Arkansas", code: "AR", areaCodes: ["479", "501", "870"] },
    { name: "California", code: "CA", areaCodes: ["209", "213", "310", "323", "408", "415", "424", "510", "530", "559", "562", "619", "626", "650", "657", "661", "669", "707", "714", "747", "760", "805", "818", "831", "858", "909", "916", "925", "949", "951"] },
    { name: "Colorado", code: "CO", areaCodes: ["303", "719", "720", "970"] },
    { name: "Connecticut", code: "CT", areaCodes: ["203", "475", "860", "959"] },
    { name: "Delaware", code: "DE", areaCodes: ["302"] },
    { name: "Florida", code: "FL", areaCodes: ["239", "305", "321", "352", "386", "407", "561", "727", "754", "772", "786", "813", "850", "863", "904", "941", "954"] },
    { name: "Georgia", code: "GA", areaCodes: ["229", "404", "470", "478", "678", "706", "762", "770", "912"] },
    { name: "Hawaii", code: "HI", areaCodes: ["808"] },
    { name: "Idaho", code: "ID", areaCodes: ["208", "986"] },
    { name: "Illinois", code: "IL", areaCodes: ["217", "224", "309", "312", "331", "447", "464", "618", "630", "708", "773", "779", "815", "847", "872"] },
    { name: "Indiana", code: "IN", areaCodes: ["219", "260", "317", "463", "574", "765", "812", "930"] },
    { name: "Iowa", code: "IA", areaCodes: ["319", "515", "563", "641", "712"] },
    { name: "Kansas", code: "KS", areaCodes: ["316", "620", "785", "913"] },
    { name: "Kentucky", code: "KY", areaCodes: ["270", "364", "502", "606", "859"] },
    { name: "Louisiana", code: "LA", areaCodes: ["225", "318", "337", "504", "985"] },
    { name: "Maine", code: "ME", areaCodes: ["207"] },
    { name: "Maryland", code: "MD", areaCodes: ["240", "301", "410", "443", "667"] },
    { name: "Massachusetts", code: "MA", areaCodes: ["339", "351", "413", "508", "617", "774", "781", "857", "978"] },
    { name: "Michigan", code: "MI", areaCodes: ["231", "248", "269", "313", "517", "586", "616", "734", "810", "906", "947", "989"] },
    { name: "Minnesota", code: "MN", areaCodes: ["218", "320", "507", "612", "651", "763", "952"] },
    { name: "Mississippi", code: "MS", areaCodes: ["228", "601", "662", "769"] },
    { name: "Missouri", code: "MO", areaCodes: ["314", "417", "573", "636", "660", "816", "975"] },
    { name: "Montana", code: "MT", areaCodes: ["406"] },
    { name: "Nebraska", code: "NE", areaCodes: ["308", "402", "531"] },
    { name: "Nevada", code: "NV", areaCodes: ["702", "725", "775"] },
    { name: "New Hampshire", code: "NH", areaCodes: ["603"] },
    { name: "New Jersey", code: "NJ", areaCodes: ["201", "551", "609", "732", "848", "856", "862", "908", "973"] },
    { name: "New Mexico", code: "NM", areaCodes: ["505", "575"] },
    { name: "New York", code: "NY", areaCodes: ["212", "315", "332", "347", "516", "518", "585", "607", "631", "646", "680", "716", "718", "838", "845", "914", "917", "929", "934"] },
    { name: "North Carolina", code: "NC", areaCodes: ["252", "336", "704", "743", "828", "910", "919", "980", "984"] },
    { name: "North Dakota", code: "ND", areaCodes: ["701"] },
    { name: "Ohio", code: "OH", areaCodes: ["216", "234", "283", "330", "380", "419", "440", "513", "567", "614", "740", "937"] },
    { name: "Oklahoma", code: "OK", areaCodes: ["405", "539", "580", "918"] },
    { name: "Oregon", code: "OR", areaCodes: ["458", "503", "541", "971"] },
    { name: "Pennsylvania", code: "PA", areaCodes: ["215", "223", "267", "272", "412", "445", "484", "570", "610", "717", "724", "814", "878"] },
    { name: "Rhode Island", code: "RI", areaCodes: ["401"] },
    { name: "South Carolina", code: "SC", areaCodes: ["803", "839", "843", "854", "864"] },
    { name: "South Dakota", code: "SD", areaCodes: ["605"] },
    { name: "Tennessee", code: "TN", areaCodes: ["423", "615", "629", "731", "865", "901", "931"] },
    { name: "Texas", code: "TX", areaCodes: ["210", "214", "254", "281", "325", "346", "361", "409", "430", "432", "469", "512", "682", "713", "737", "806", "817", "830", "832", "903", "915", "936", "940", "956", "972", "979"] },
    { name: "Utah", code: "UT", areaCodes: ["385", "435", "801"] },
    { name: "Vermont", code: "VT", areaCodes: ["802"] },
    { name: "Virginia", code: "VA", areaCodes: ["276", "434", "540", "571", "703", "757", "804"] },
    { name: "Washington", code: "WA", areaCodes: ["206", "253", "360", "425", "509", "564"] },
    { name: "West Virginia", code: "WV", areaCodes: ["304", "681"] },
    { name: "Wisconsin", code: "WI", areaCodes: ["262", "274", "414", "534", "608", "715", "920"] },
    { name: "Wyoming", code: "WY", areaCodes: ["307"] }
  ];
  return states;
};

// Function to get sum of number array
export const sum = (numbers) => numbers.reduce((accumulator, currentValue) => {
  return accumulator + currentValue;
}, 0);

// Function to get percentages
export const getPercentages = (num1 = 0, num2 = 0) => {
  const sum = num1 + num2;

  if (sum === 0) {
    return [0, 0]; // To handle the case when both numbers are zero
  }

  const percent1 = (num1 / sum) * 100;
  const percent2 = (num2 / sum) * 100;

  return [percent1, percent2];
};

// Function to get rounded percentage number
export const getRoundedPercentage = (num) => {
  const roundedString = num.toFixed(2);

  // If you need the result as a number:
  return parseFloat(roundedString);
};

// Remove html tags
export const removeHtmlTags = (str) => {
  return str
    .replace(/<br\s*\/?>/gi, '\n') // Replace <br> tags with a newline character
    .replace(/<\/?[^>]+(>|$)/g, ""); // Remove all other HTML tags
};

// Function to extract links from plain text
export const extractLinks = (text) => {
  // Regular expression to match URLs
  const urlRegex = /(https?:\/\/[^\s]+)/g;

  // Extract all URLs
  const links = text.match(urlRegex) || [];

  return links;
};

// Function to get media type from s3 url
export const getMediaTypeFromS3Url = async (url) => {
  try {
    const response = await axios.head(url);
    const contentType = response.headers['content-type'];

    if (contentType.startsWith('image/')) {
      return 'IMAGE';
    } else if (contentType.startsWith('video/')) {
      return 'VIDEO';
    } else {
      return 'UNKNOWN';
    }
  } catch (error) {
    throw new Error(`Error fetching the URL: ${error.message}`);
  }
};