// store/modules/user.js
import axios from "axios";

const state = {
  user: null,
  isAuthenticated: false,
  users: [],
};

const mutations = {
  setUser(state, user) {
    state.user = user;
    state.isAuthenticated = !!user;
  },
  addUser(state, user) {
    state.users.push(user);
  },
};

const actions = {
  async login({ commit }, userData) {
    try {
      // Make the API request to login using Axios
      const response = await axios.post(
        `${process.env.VUE_APP_API_URL}/users/login`,
        userData,
      );

      // Check if the request was successful
      if (response.status === 201) {
        // Extract user data from the response
        const userData = response.data;

        // Commit setUser mutation with user data
        commit("setUser", {
          id: userData.accountId,
          userName: userData.userName,
          email: userData.email,
          role: userData.role,
          createdAt: userData.createdAt,
          updatedAt: userData.updatedAt,
        });

        // Return any relevant data from the response
        return { success: true, message: "Login successful" };
      } else {
        // If the request was not successful, throw an error
        throw new Error("Login failed");
      }
    } catch (error) {
      // Handle errors, such as network issues or server errors
      console.error("Login error:", error);
      return {
        success: false,
        message: "Login failed. Please try again later.",
      };
    }
  },
  async signup({ commit }, userData) {
    try {
      // Send a POST request with user data
      const response = await axios.post(
        `${process.env.VUE_APP_API_URL}/users/signup`,
        userData,
      );

      // Check if the request was successful
      if (response.status === 201) {
        // Add new user to the store (replace with database insertion in production)
        commit("addUser", response.data.user);
        return Promise.resolve();
      } else {
        return Promise.reject("Signup failed");
      }
    } catch (error) {
      return Promise.reject(error.response.data.error);
    }
  },
  logout({ commit }) {
    // Perform logout logic, e.g., clear local storage, invalidate tokens, etc.
    // Then, commit setUser mutation with null to clear user data
    commit("setUser", null);
  },
};

const getters = {
  currentUser: state => state.user,
  isAuthenticated: state => state.isAuthenticated,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
