<script setup>
import { computed, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { getFormattedDate } from "@/utils";

const store = useStore();
const router = useRouter();

const userId = computed(() => store.getters["user/currentUser"].id);
const jobs = computed(() => store.getters["jobData/getJobs"]);

const jobsHeadings = [
  "Job Name",
  "Recipients Count",
  "Texts Sent Count",
  "Last Active Date",
  "Actions",
];

onMounted(async () => {
  await store.dispatch("jobData/fetchJobs", { accountId: userId.value });
});

const onViewDetails = (jobId) => {
  router.push(`/chats/${jobId}`);
};
</script>

<template>
  <div class="container-fluid py-4">
    <h2 class="text-white mb-4">Chats</h2>
    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-12">
            <div class="card min-vh-50">
              <div class="card-header pb-0">
                <h6>Job List</h6>
              </div>
              <div class="card-body px-0 pt-0 pb-2">
                <table class="table align-items-center justify-content-center mb-0">
                  <thead>
                    <tr>
                      <th
                        v-for="(heading, index) in jobsHeadings"
                        :key="index"
                        class="text-uppercase text-secondary text-xxs font-weight-bolder opacity"
                      >
                        {{ heading }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in jobs" :key="index">
                      <td class="px-4">
                        <p class="text-xs font-weight-bold mb-0">
                          {{ item.name }}
                        </p>
                      </td>
                      <td class="px-4">
                        <p class="text-xs font-weight-bold mb-0">
                          {{ item.receipientsCount || 0 }}
                        </p>
                      </td>
                      <td class="px-4">
                        <p class="text-xs font-weight-bold mb-0">
                          {{ item.messagesSent || 0 }}
                        </p>
                      </td>
                      <td class="px-4">
                        <p class="text-xs font-weight-bold mb-0">
                          {{ getFormattedDate(item.updatedAt) }}
                        </p>
                      </td>
                      <td class="px-4">
                        <i
                          class="fas fa-eye text-primary text-sm opacity-10"
                          @click.prevent="onViewDetails(item.jobId)"></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
