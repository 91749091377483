<script setup>
import { defineProps, computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import ArgonButton from "@/components/Core/ArgonButton.vue";
import { getFormattedDate } from "@/utils";

const props = defineProps({
  headings: {
    type: Array,
    required: true,
  },
  data: {
    type: Array,
    required: true,
  },
});

const router = useRouter();
const store = useStore();

const userRole = computed(() => store.getters["user/currentUser"]?.role);
const userId = computed(() => store.getters["user/currentUser"].id);

const onRowClick = (job) => {
  // if (userRole.value !== "USER") return;
  store.commit("jobData/setJob", job);
  if (router.currentRoute.value.name == "JobReports") {
    router.push(`/${job.jobType.toLowerCase()}/${job.jobId}/reports`);
  } else {
    router.push(`/${job.jobType.toLowerCase()}/${job.jobId}`);
  }
};

const approveJob = async (job) => {
  await store.dispatch("jobData/approveJob", { jobId: job.jobId });
  await store.dispatch("jobData/fetchJobs",  { accountId: userId.value });
};

const onMessageStatusClick = (jobId) => {
  router.push(`/chats/${jobId}`);
};

/* To consider later
const onStatusClick = (job) => {
  if (userRole.value === "ADMIN") {
    router.push(`/${job.jobType.toLowerCase()}/${job.jobId}/edit`);
  }
};
*/
</script>

<template>
  <div class="card">
    <div class="card-header pb-0">
      <h6>P2P SMS Jobs</h6>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0">
        <table class="table align-items-center justify-content-center mb-0">
          <thead>
            <tr>
              <th
                v-for="(heading, index) in headings"
                :key="index"
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                {{ heading }}
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(item, index) in props.data" :key="index">
              <tr  @click.prevent="onRowClick(item)">
                <td class="px-4" rowspan="2">
                  <p class="text-xs font-weight-bold mb-0">
                    {{ getFormattedDate(item.startDate) }}
                  </p>
                </td>
                <td class="px-4" rowspan="2">
                  <p class="text-xs font-weight-bold mb-0">
                    {{ item.jobType }}
                  </p>
                </td>
                <td class="px-4" rowspan="2">
                  <p class="text-xs font-weight-bold mb-0">
                    {{ item.name }}
                  </p>
                </td>
                <td
                  class="px-4 cursor-pointer"
                  rowspan="2"
                >
                  <p class="text-xs font-weight-bold mb-0">
                    {{ item.status.toUpperCase() }}
                    <argon-button
                      v-if="item.status ==='pending' && userRole === 'ADMIN'"
                      color="success"
                      size="sm"
                      class="action-btn rounded-circle ms-2 px-0"
                      @click.stop="approveJob(item)"
                    >
                      <i class="fas fa-check"></i>
                    </argon-button>
                  </p>
                </td>
                <td class="px-4 text-center" rowspan="2">
                  <div class="d-flex flex-column justify-content-between">
                    <p class="text-xs font-weight-bold mb-0">
                      {{ item.agents?.length || 0 }}
                    </p>
                    <p
                      class="text-xs font-weight-bold mb-0 mx-auto border border-bottom-0 border-start-0 border-end-0"
                    >
                      Agents
                    </p>
                  </div>
                </td>
                <td
                  class="px-4 text-center border-bottom-0 cursor-pointer"
                  @click.stop="onMessageStatusClick(item.jobId)"
                >
                  <p class="text-xs font-weight-bold mb-0">
                    {{ item.messagesSent || 0 }}
                  </p>
                </td>
                <td
                  class="px-4 text-center border-bottom-0 cursor-pointer"
                  @click.stop="onMessageStatusClick(item.jobId)"
                >
                  <p class="text-xs font-weight-bold mb-0">
                    {{ (+item.messagesOptOut + (+item.messagesNonOptOut)) || 0 }}
                  </p>
                </td>
              </tr>
              <tr class="border-bottom-0">
                <td
                  class="px-4 text-center border-1 border-start-0 border-end-0 border-top-0 border-light"
                  colspan="2"
                >
                  <div class="d-flex flex-column align-items-center">
                    <div class="progress">
                      <div
                        class="progress-bar bg-gradient-info"
                        role="progressbar"
                        aria-valuenow="{item.leadsRemaining}"
                        aria-valuemin="0"
                        aria-valuemax="100"
                        style="width: 0"
                      ></div>
                    </div>
                    <p class="text-xs font-weight-bold mb-0">
                      Leads Remaining: {{ item.leadsRemaining || 0 }}
                    </p>
                  </div>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
