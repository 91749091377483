<script setup>
import { computed, onBeforeMount, onMounted, ref } from "vue";
import { useStore } from "vuex";
import ArgonButton from "@/components/Core/ArgonButton.vue";
import ArgonDropdown from "@/components/Core/ArgonDropdown.vue";
import SearchBar from "@/components/PageLayout/SearchBar.vue";

const body = document.getElementsByTagName("body")[0];

const store = useStore();
const identities = computed(() => store.getters["identityData/getIdentities"]);
const dncs = computed(() => store.getters["dncData/getDNCs"]);
const loading = computed(() => store.getters["dncData/isLoading"]);
const error = computed(() => store.getters["dncData/getError"]);
const userId = computed(() => store.getters["user/currentUser"].id);

const dncSearch = ref("");
const selectedIdentity = ref(null);

onBeforeMount(() => {
  store.state.app.hideConfigButton = false;
  store.state.app.showNavbar = true;
  store.state.app.showSidenav = true;
  store.state.app.showFooter = true;
  body.classList.add("bg-gray-100");
});

onMounted(async () => {
  await store.dispatch("dncData/fetchDNCs", { userId: userId.value });
});

const onIdentitySelect = async (identity) => {
  selectedIdentity.value = identity;
  await store.dispatch("dncData/filterDNCByIdentity", {
    userId: userId.value,
    identityId: identity.identityId,
  });
};

const handleDNCSearch = async () => {
  await store.dispatch("dncData/searchDNCs", {
    userId: userId.value,
    query: dncSearch.value,
  });
};

const onAdd = () => {
  store.commit("dncData/toggleAddDNCPanel");
};
</script>

<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12">
        <h2 class="text-white mb-4">DNC Manager</h2>
        <div class="card min-vh-50">
          <div class="card-body">
            <p>
              The Do-Not-Contact database contains records that have opted out of being
              contacted in the future. Records here will be removed from subsequent phone
              list uploads.
            </p>
            <div class="d-flex align-items-center mb-4">
              <search-bar v-model="dncSearch" @input="handleDNCSearch" />
              <div class="d-flex align-items-center ms-4">
                Identity:
                <argon-dropdown class="ms-3">
                  <template v-slot:title>
                    <argon-button color="light" class="dropdown-toggle">
                      {{ selectedIdentity?.identityName || "Identity" }}
                    </argon-button>
                  </template>
                  <a
                    v-for="(identity, index) in identities"
                    :key="index"
                    class="dropdown-item d-flex align-items-center"
                    @click="onIdentitySelect(identity)"
                  >
                    <label class="mb-0">{{ identity.identityName }}</label>
                  </a>
                </argon-dropdown>
              </div>
              <argon-button
                color="success"
                size="sm"
                class="action-btn rounded-circle ms-auto px-2"
                @click="onAdd()"
              >
                <i class="fas fa-plus text-xs text-white" aria-hidden="true"></i>
              </argon-button>
            </div>
            <div v-if="loading">Loading...</div>
            <div v-else-if="error">{{ error }}</div>
            <div v-else class="table-responsive p-0">
              <table class="table align-items-center justify-content-center mb-0">
                <thead>
                  <tr>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Value
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Type
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Added By
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Added On
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Reason
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(dnc, index) in dncs" :key="index">
                    <td class="px-4">
                      {{ dnc.value }}
                    </td>
                    <td class="px-4">
                      {{ dnc.dncType }}
                    </td>
                    <td class="px-4">
                      {{ dnc.user?.userName }}
                    </td>
                    <td class="px-4">
                      {{ dnc.addedDate }}
                    </td>
                    <td class="px-4">
                      {{ dnc.reason }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
