<script setup>
import { ref, watch } from "vue";
import { useStore } from "vuex";
import ArgonButton from "@/components/Core/ArgonButton.vue";
import SearchBar from "@/components/PageLayout/SearchBar.vue";

const store = useStore();

const props = defineProps({
  data: Object,
  emitValidState: Function,
  addPhoneList: Function,
});

const { emitValidState, addPhoneList } = props;

const formData = ref({ ...props.data });

// Watch for changes in phoneListData and update formData accordingly
watch(
  () => props.data,
  (newValue) => {
    formData.value = { ...newValue };
  },
  { deep: true, immediate: true }
);

// Watch for changes in formData
watch(
  formData,
  () => {
    emitValidState(true);
  },
  { deep: true }
);

const onAdd = () => {
  addPhoneList();
};

const onAssign = (phoneList) => {
  formData.value = {
    available: formData.value.available?.filter(
      (item) => item.listId !== phoneList.listId
    ),
    assigned: [...formData.value.assigned, phoneList],
  };
  store.commit("jobData/updatePhoneListStepData", formData.value);
};

const onRemoveDuplicate = () => {
  //
};

const onKeepDuplicate = () => {
  //
};

const onUnassign = (phoneList) => {
  formData.value = {
    available: [...formData.value.available, phoneList],
    assigned: formData.value.assigned?.filter((item) => item.id !== phoneList.id),
  };
  store.commit("jobData/updatePhoneListStepData", formData.value);
};
</script>

<template>
  <div class="row">
    <div class="col-12">
      <h2 class="text-dark mb-4">Phone List</h2>
    </div>
    <div class="col-lg-6 col-12">
      <div class="d-flex align-items-center">
        <h4 class="text-dark mb-3 me-4">Available Phone Lists</h4>
        <search-bar />
      </div>
      <div class="d-flex align-items-center">
        <argon-button class="rounded-circle ms-auto px-3" @click="onAdd">
          +
        </argon-button>
      </div>
      <div class="table-responsive p-0 mt-4">
        <table class="table align-items-center justify-content-center mb-0">
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Name
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                State
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Leads Remaining
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              ></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in formData.available" :key="index">
              <td class="px-4">{{ item.listName }}</td>
              <td class="px-4">{{ item.listState }}</td>
              <td class="px-4">{{ item.leadsRemaining || 0 }}</td>
              <td class="px-4">
                <div class="d-flex">
                  <argon-button
                    color="primary"
                    size="sm"
                    class="action-btn rounded-circle ms-auto px-2"
                    @click="onAssign(item)"
                  >
                    <i class="fas fa-user-plus text-xs text-white" aria-hidden="true"></i>
                  </argon-button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="col-lg-6 col-12">
      <div class="d-flex align-items-center">
        <h4 class="text-dark mb-3 me-4">Assigned Phone Lists</h4>
      </div>
      <div class="table-responsive p-0 mt-4">
        <table class="table align-items-center justify-content-center mb-0">
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Name
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                State
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Leads Remaining
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              ></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in formData.assigned" :key="index">
              <td class="px-4">{{ item.listName }}</td>
              <td class="px-4">{{ item.listState }}</td>
              <td class="px-4">{{ item.leadsRemaining || 0 }}</td>
              <td class="px-4">
                <div class="d-flex">
                  <argon-button
                    v-if="item.deduplicate"
                    color="success"
                    size="sm"
                    class="action-btn rounded-circle ms-auto me-2 px-2"
                    @click="onRemoveDuplicate"
                  >
                    <i class="fas fa-users text-xs text-white" aria-hidden="true"></i>
                  </argon-button>
                  <argon-button
                    v-if="!item.deduplicate"
                    color="warning"
                    size="sm"
                    class="action-btn rounded-circle ms-auto me-2 px-2"
                    @click="onKeepDuplicate"
                  >
                    <i class="fas fa-users text-xs text-white" aria-hidden="true"></i>
                  </argon-button>
                  <argon-button
                    color="dark"
                    size="sm"
                    class="action-btn rounded-circle ms-auto px-2"
                    @click="onUnassign(item)"
                  >
                    <i
                      class="fas fa-user-minus text-xs text-white"
                      aria-hidden="true"
                    ></i>
                  </argon-button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<style>
.action-btn {
  width: 34px;
  height: 34px;
}
</style>
