<script setup>
import { onBeforeMount, computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import ArgonButton from "@/components/Core/ArgonButton.vue";
import ArgonDropdown from "@/components/Core/ArgonDropdown.vue";
import SearchBar from "@/components/PageLayout/SearchBar.vue";
import SmsJobsTable from "./SMSJobsTable.vue";

const body = document.getElementsByTagName("body")[0];

const store = useStore();
const router = useRouter();
const isRTL = computed(() => store.state.app.isRTL);
const userId = computed(() => store.getters["user/currentUser"].id);
const identities = computed(() => store.getters["identityData/getIdentities"]);
const jobs = computed(() => store.getters["jobData/getJobs"]);

const jobSearch = ref("");
const selectedIdentity = ref(null);
const selectedStatus = ref(null);
const selectedSendType = ref(null);

const statuses = ref([
  {
    id: "active",
    name: "Active",
    value: false,
  },
  {
    id: "paused",
    name: "Paused",
    value: false,
  },
  {
    id: "pending",
    name: "Pending",
    value: false,
  },
  {
    id: "incomplete",
    name: "Incomplete",
    value: false,
  },
]);

const sendTypes = ref([
  {
    id: 1,
    name: "House Send",
    value: "House File",
  },
  {
    id: 2,
    name: "P2P Send",
    value: "P2P",
  },
  {
    id: 3,
    name: "Soapbox P2P Send",
    value: "Soapbox P2P",
  },
]);

const smsJobsHeadings = [
  "Send Date",
  "Send Type",
  "Job Name",
  "Status",
  "Assigned Agents",
  "Messages Sent",
  "Messages Received",
];

onBeforeMount(() => {
  store.state.app.hideConfigButton = false;
  store.state.app.showNavbar = true;
  store.state.app.showSidenav = true;
  store.state.app.showFooter = true;
  body.classList.add("bg-gray-100");
});

onMounted(async () => {
  await store.dispatch("jobData/fetchJobs", { accountId: userId.value });
});

const filterJobs = async () => {
  const payload = {
    accountId: userId.value,
  };

  if (selectedIdentity.value) {
    payload.identityId = selectedIdentity.value.identityId;
  }

  if (selectedStatus.value) {
    payload.status = selectedStatus.value.name?.toLowerCase();
  }

  if (selectedSendType.value) {
    payload.jobType = selectedSendType.value.value;
  }

  if (jobSearch.value) {
    payload.query = jobSearch.value;
  }

  await store.dispatch("jobData/filterJobs", payload);
};

const onIdentitySelect = async (identity) => {
  selectedIdentity.value = identity;
  await filterJobs();
};

const handleJobSearch = async () => {
  await filterJobs();
};

const onStatusChange = async (status) => {
  selectedStatus.value = status;
  await filterJobs();
};

const onSendTypeChange = async (sendType) => {
  selectedSendType.value = sendType;
  await filterJobs();
};

const onCreateJobClick = () => {
  router.push("/select-send-type");
};
</script>
<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-12">
            <div class="d-flex mb-4">
              <div
                class="pe-md-3 d-flex align-items-center"
                :class="isRTL ? 'me-md-0' : 'ms-md-0'"
              >
                <search-bar v-model="jobSearch" @input="handleJobSearch" />
              </div>
              <argon-dropdown class="me-1">
                <template v-slot:title>
                  <argon-button color="light" class="dropdown-toggle">
                    {{ selectedStatus?.name || "Status" }}
                  </argon-button>
                </template>
                <a
                  v-for="(status, index) in statuses"
                  class="dropdown-item d-flex align-items-center"
                  href="#"
                  :key="index"
                  @click="onStatusChange(status)"
                >
                  <!-- <input type="checkbox" :id="status.id" :name="status.name" :checked="status.value" /> -->
                  <label :for="status.name" class="mb-0">{{ status.name }}</label>
                </a>
              </argon-dropdown>
              <div class="d-flex align-items-center me-3">
                <argon-dropdown class="ms-3">
                  <template v-slot:title>
                    <argon-button color="light" class="dropdown-toggle">
                      {{ selectedIdentity?.identityName || "Identity" }}
                    </argon-button>
                  </template>
                  <a
                    v-for="(identity, index) in identities"
                    :key="index"
                    class="dropdown-item d-flex align-items-center"
                    @click="onIdentitySelect(identity)"
                  >
                    <label class="mb-0">{{ identity.identityName }}</label>
                  </a>
                </argon-dropdown>
              </div>
              <argon-dropdown>
                <template v-slot:title>
                  <argon-button color="light" class="dropdown-toggle">
                    {{ selectedSendType?.name || "Send Type" }}
                  </argon-button>
                </template>
                <a
                  v-for="(sendType, index) in sendTypes"
                  :key="index"
                  class="dropdown-item d-flex align-items-center"
                  href="#"
                  @click="onSendTypeChange(sendType)"
                >
                  <!-- <input type="checkbox" :id="sendType.id" :name="sendType.name" /> -->
                  <label :for="sendType.name" class="mb-0">{{ sendType.name}}</label>
                </a>
              </argon-dropdown>
              <div class="ms-auto">
                <argon-button color="dark" @click="onCreateJobClick">
                  Create Job +
                </argon-button>
              </div>
            </div>
            <sms-jobs-table :headings="smsJobsHeadings" :data="jobs" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
