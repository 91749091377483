<script setup>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import ArgonInput from "@/components/Core/ArgonInput.vue";
import ArgonButton from "@/components/Core/ArgonButton.vue";

const store = useStore();
const userId = computed(() => store.getters["user/currentUser"].id);
const agent = computed(() => store.getters["agentData/getSelectedAgent"]);
const error = computed(() => store.getters["agentData/getError"]);
const isRTL = computed(() => store.state.isRTL);

const agentData = ref({});

const toggleAddAgentPanel = () => store.commit("jobData/toggleAddAgentPanel");

const saveAgent = async () => {
  const payload = {
    accountId: userId.value,
    email: agentData.value.email,
    firstName: agentData.value.firstName,
    lastName: agentData.value.lastName,
    password: agentData.value.password,
    domain: window.location.hostname,
  };
  await store.dispatch("agentData/addAgent", payload);
  toggleAddAgentPanel();
  agentData.value = {};
  if (!error.value) {
    await store.dispatch("agentData/fetchAgents", { userId: userId.value });
    store.commit("jobData/addAgentStepData", agent.value);
  }
};
</script>
<template>
  <div class="fixed-plugin">
    <div class="shadow-lg card p-4">
      <div class="pb-0">
        <div class="" :class="isRTL ? 'float-end' : 'float-start'">
          <h5 class="mt-3">Agent Manager</h5>
          <p>Create P2P SMS Agent</p>
        </div>
        <div
          class="mt-4"
          @click="toggleAddAgentPanel"
          :class="isRTL ? 'float-start' : 'float-end'"
        >
          <button class="p-0 btn btn-link text-dark fixed-plugin-close-button">
            <i class="fa fa-close"></i>
          </button>
        </div>
      </div>
      <form role="form" @submit.prevent="saveAgent">
        <div class="mb-3">
          <label for="email-input" class="form-control-label">Email:</label>
          <argon-input
            id="email"
            type="text"
            name="email"
            size="md"
            v-model="agentData.email"
          />
        </div>
        <div class="mb-3">
          <label for="password-input" class="form-control-label">Password:</label>
          <argon-input
            id="password"
            type="password"
            name="password"
            size="md"
            v-model="agentData.password"
          />
        </div>
        <div class="mb-3">
          <label for="firstname-input" class="form-control-label">First Name:</label>
          <argon-input
            id="firstname"
            type="text"
            name="firstname"
            size="md"
            v-model="agentData.firstName"
          />
        </div>
        <div class="mb-3">
          <label for="lastname-input" class="form-control-label">Last Name:</label>
          <argon-input
            id="lastname"
            type="text"
            name="lastname"
            size="md"
            v-model="agentData.lastName"
          />
        </div>
        <div class="text-left">
          <argon-button
            class="border-radius-2xl"
            variant="gradient"
            color="success"
            size="md"
            >Save</argon-button
          >
        </div>
      </form>
    </div>
  </div>
</template>
